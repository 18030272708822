import ContentLoading from "@/components/ContentLoading";
import StatusTag from "@/components/StatusTag";
import { companyConfig } from "@/config";
import { getExpiredStatus } from "@/enums/enums";
import CaseCompleted from "@/features/tax/status/CaseCompleted";
import EmptyCalculationResult from "@/features/tax/status/EmptyCalculationResult";
import useFallbackCarLogo from "@/hooks/useFallbackCarLogo";
import { printMakeModelOrLink } from "@/utils/printHelpers";
import { formatDate, formatNumber, formatPhone } from "@/utils/string";
import { isAcceptedState, isAssessmentState, isCompletedState, isInitialState } from "@/utils/taxCaseStatus";
import { isOutsideWorkingHours } from "@/utils/utils";
import { Button } from "@headlessui/react";
import { ArrowDownTrayIcon, ArrowLongRightIcon, ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { Link, useSearch } from "@tanstack/react-router";
import clsx from "clsx";
import { t } from "i18next";
import { TaxCaseResource } from "../../types/taxTypes";
import Assessment from "./Assessment";
import PriceQuote from "./PriceQuote";
import { TaxCaseProgressTimeLine } from "./TaxCaseProgressTimeLine";
import CaseAccepted from "./status/Acccepted";
import CaseExpired from "./status/CaseExpired";
import InvoiceGenerated from "./status/InvoiceGenerated";
import InvoiceGenerating from "./status/InvoiceGenerating";
import PreAssessed from "./status/PreAssessed";
import CasePurchased from "./status/Purchased";

type Props = {
    taxCase: TaxCaseResource | null;
}
export default function TaxCasePreview({ taxCase }: Props) {
    const search = useSearch({ from: "/_protected/_tax/tax/" })
    if (!taxCase) {
        return (
            <div className="sm:p-8 bg-white p-4 max-h-full w-full">
                <ContentLoading />
            </div>
        )
    }
    const isInitial = isInitialState(taxCase.status)
    return (
        <div className="sm:p-8 bg-white p-4 max-h-full overflow-y-auto w-full space-y-8 relative flow-root">
            <div className="mb-16 space-y-4 max-md:hidden">
                <h3 className="text-dark-blue font-semibold">{t("overview")}</h3>
                <TaxCaseProgressTimeLine flags={taxCase.flags} status={taxCase.status} />
            </div>
            <CaseStatus taxCase={taxCase} />

            <VehicleSpecs vehicle={taxCase.vehicle} status={taxCase.status} />

            {isInitial ? (
                <>
                    {isOutsideWorkingHours(taxCase.createdAt) && (
                        <div className="text-sm border p-3 rounded-sm border-yellow-200 bg-yellow-50">
                            <p>{t("content:outOfNormalWorkingHours")}</p>
                            <p>{t("content:outOfNormalWorkingHoursContact", { phone: formatPhone(companyConfig.phone.nicklasTax), email: companyConfig.emails.tax })}</p>
                        </div>
                    )}
                    {/* <p>TODO: Show robot estimation</p> */}
                </>
            ) : (
                <>
                    <Assessment assessment={taxCase.assessment} />
                    <PriceQuote assessment={taxCase.assessment} />
                </>
            )}


            <div className="pt-12"></div>
            <div className="fixed bottom-0 pl-10 w-full right-0 ">
                <div className="w-full max-w-3xl ml-auto pb-4 sm:px-8 px-4 pt-4 bg-white shadow-[0px_-2px_10px_2px_rgba(0,0,0,0.06)] flex items-center justify-end gap-2">
                    {taxCase.links.offer?.uri ? (
                        <a href={taxCase.links.offer?.uri}
                            download={taxCase.links.offer?.name}
                            target="_blank"
                            className="btn btn-outline"
                        >
                            <ArrowDownTrayIcon className="size-5" aria-hidden="true" />
                            PDF
                        </a>
                    ) : (
                        <Button className="btn btn-outline" disabled>
                            <ArrowDownTrayIcon className="size-5" aria-hidden="true" />
                            PDF
                        </Button>
                    )}
                    <Link to="/tax/$id"
                        params={{ id: taxCase.id }}
                        // @ts-expect-error
                        state={{ search: search }}
                        className="btn btn-primary"
                    >
                        {t("goTo", { context: "theCase" })}
                        <ArrowLongRightIcon className="size-5" aria-hidden="true" />
                    </Link>

                </div>

            </div>

        </div>
    )
}



type VehicleSpecsProps = {
    vehicle: TaxCaseResource['vehicle'];
    status: TaxCaseResource['status'];
}
function VehicleSpecs({ vehicle, status }: VehicleSpecsProps) {
    if (!vehicle) {
        return (
            <div className="px-4 py-8 text-center rounded-md bg-gray-50">
                <h2 className="text-lg font-semibold text-dark-blue">
                    {t("noVehicleData")}
                </h2>
            </div>
        )
    }

    return (
        <div className="flex max-sm:flex-col">
            <div className="bg-gray-50 p-6 flex-1 rounded-lg">
                <div className="flex pb-6 flex-wrap-reverse gap-8 justify-between">
                    <dl className="flex-1">
                        <StatusTag {...status} />
                        <h3 className="font-semibold text-dark-blue my-2">
                            {printMakeModelOrLink(vehicle.brand, vehicle.model, vehicle.link)}
                            {vehicle.brand && vehicle.model && (
                                <Button className="inline group relative btn-text align-top text-text-primary"
                                    onClick={() => window.navigator.clipboard.writeText(`${vehicle?.brand} ${vehicle?.model ?? ""}`)}
                                >
                                    <ClipboardDocumentIcon className="size-5" />
                                    <div className={clsx("bg-gray-900/75 text-sm whitespace-nowrap font-normal text-gray-100 bottom-full right-0 absolute leading-7 px-2 pointer-events-none rounded-sm transition",
                                        "opacity-0 translate-y-0.5 group-hover:opacity-100 group-hover:translate-y-0 group-hover:pointer-events-auto"
                                    )}>
                                        {t("clickToCopy")}
                                    </div>
                                </Button>
                            )}
                        </h3>
                        <div className="flex gap-1 text-sm mb-1">
                            <dt>{t("vin")}:</dt>
                            {vehicle?.vin ? (
                                <dd
                                    onClick={() => window.navigator.clipboard.writeText(vehicle?.vin || "")}
                                >
                                    {vehicle?.vin}
                                    <Button className="inline ml-0.5 group relative btn-text align-top"
                                        onClick={() => window.navigator.clipboard.writeText(vehicle?.vin ?? "")}
                                    >
                                        <ClipboardDocumentIcon className="size-5" />
                                        <div className={clsx("bg-gray-900/75 text-sm whitespace-nowrap font-normal text-gray-100 bottom-full right-0 absolute leading-7 px-2 pointer-events-none rounded-sm transition",
                                            "opacity-0 translate-y-0.5 group-hover:opacity-100 group-hover:translate-y-0 group-hover:pointer-events-auto"
                                        )}>
                                            {t("clickToCopy")}
                                        </div>
                                    </Button>
                                </dd>
                            ) : t("notAvailable")}
                        </div>
                    </dl>
                    {vehicle?.image?.uri ? (
                        <div className="flex justify-center sm:flex-1 flex-auto relative">
                            <img
                                src={vehicle.image.uri}
                                className="w-60 h-auto flex-none brightness-125"
                                alt={vehicle.brand ?? ""}
                                loading="lazy"
                            />
                            <div className='absolute bottom-0 inset-x-0 bg-gray-50 h-[6%]'></div>
                        </div>
                    ) : (
                        <CarLogo carBrand={vehicle?.brand ?? ""} />
                    )}
                </div>
                <dl className="grid sm:grid-cols-3 gap-3">
                    <div className="sm:bg-white rounded-md sm:p-4 max-sm:flex max-sm:gap-3 max-sm:items-center">
                        <dt className="text-xs font-medium">{t("firstRegDate", { context: "short" })}</dt>
                        <dd className="text-dark-blue font-semibold text-sm">{formatDate(vehicle?.firstRegDate)}</dd>
                    </div>
                    <div className="sm:bg-white rounded-md sm:p-4 max-sm:flex max-sm:gap-3 max-sm:items-center">
                        <dt className="text-xs font-medium">{t("mileage")}</dt>
                        <dd className="text-dark-blue font-semibold text-sm">{formatNumber(vehicle?.mileage)} km.</dd>
                    </div>
                    <div className="sm:bg-white rounded-md sm:p-4 max-sm:flex max-sm:gap-3 max-sm:items-center">
                        <dt className="text-xs font-medium">{t("co2Emissions")}</dt>
                        <dd className="text-dark-blue font-semibold text-sm">{formatNumber(vehicle?.emissionWltp)}</dd>
                    </div>
                </dl>

            </div>
        </div>
    )
}


function CarLogo({ carBrand }: { carBrand: string | undefined }) {
    const src = useFallbackCarLogo(carBrand);
    return (
        <div className="flex justify-center sm:flex-1 flex-auto">
            <img src={src}
                className=" h-auto max-h-36 flex-none mix-blend-multiply"
                alt={carBrand}
                loading="lazy"
            />
        </div>
    )
}


function CaseStatus({ taxCase }: { taxCase: TaxCaseResource }) {
    const isExpired = taxCase.status.value === getExpiredStatus()?.value;
    const isPreassessed = isAssessmentState(taxCase.status)
    const isAccepted = isAcceptedState(taxCase.status)
    const isGeneratingInvoice = taxCase.flags?.invoiceStartedAt
    const hasGeneratedInvoice = taxCase.flags?.invoiceDueAt
    const isPaid = !!taxCase.flags?.paymentReceivedAt;
    const isCompleted = isCompletedState(taxCase.status)
    if (isCompleted) return <CaseCompleted label={t("content:The_vehicle_has_now_been_valued")} />;

    function renderInner() {
        if (isExpired) return <CaseExpired taxCaseId={taxCase.id} />;

        if (isPaid) return <CasePurchased />;
        else if (hasGeneratedInvoice) return <InvoiceGenerated />;
        else if (isGeneratingInvoice) return <InvoiceGenerating />;
        else if (isAccepted) return <CaseAccepted />;

        if (isPreassessed) return <PreAssessed />
        return <EmptyCalculationResult />;
    }
    return (
        <div className="p-4 rounded-sm border bg-gray-50">
            {renderInner()}
        </div>
    )
}

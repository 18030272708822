import BasicSlideOver from '@/components/BasicSlideOver'
import ContentLoading from '@/components/ContentLoading'
import PaginatedDataPaginationButtons from '@/components/PaginatedDataPaginationButtons'
import { Spinner } from '@/components/Spinner'
import { statuses } from '@/enums/enums'
import TaxCaseTable from '@/features/tax/TaxCaseTable'
import { useDealerStatsQuery } from '@/hooks/useDealer'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { useMedia } from '@/hooks/useMedia'
import { usePaginatedTaxCasesQuery } from '@/hooks/useTax'
import { PaginatedData } from '@/types/appTypes'
import { TaxCaseResource } from '@/types/taxTypes'
import { formatPercent, getStatusColorClassName } from '@/utils/string'
import { debounce } from '@/utils/utils'
import { Input, Listbox, ListboxButton, ListboxOption, ListboxOptions, Radio, RadioGroup, Transition, TransitionChild } from "@headlessui/react"
import { ArrowPathIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, ListBulletIcon, MagnifyingGlassIcon, PlusIcon, TableCellsIcon } from "@heroicons/react/24/outline"
import { Link, createFileRoute, useNavigate, useSearch } from '@tanstack/react-router'
import clsx from 'clsx'
import { t } from 'i18next'
import { ChangeEvent, useCallback, useState } from 'react'
import { z } from 'zod'
import TaxCaseList from '../../../features/tax/TaxCaseList'
import TaxCasePreview from '../../../features/tax/TaxCasePreview'


const schema = z.object({
    page: z.number().optional().catch(1),
    per_page: z.number().optional().catch(20),
    q: z.string().or(z.number()).optional(),
    sort: z.enum(['id', '-id']).optional().catch('-id'),
    status: z.coerce.number().optional().catch(7),
})

export const Route = createFileRoute('/_protected/_tax/tax/')({
    meta: () => [{ title: "TAX" }],
    validateSearch: schema,
    component: TaxComponentOuter
})

function TaxComponentOuter() {
    const { data, error, isPending, isFetching } = usePaginatedTaxCasesQuery()

    if (error) {
        return <p>{JSON.stringify(error.message)}</p>
    }
    if (!data) {
        return <ContentLoading />
    }
    return (
        <Transition appear show={!!data} as="div" className="transition duration-1000 data-[closed]:opacity-0 md:h-full max-md:bg-white flex flex-col">
            {/* <Header /> */}
            <Statitics />
            <TaxComponent isLoading={isPending || isFetching} data={data} />
        </Transition>
    )
}

type Props = {
    data: PaginatedData<TaxCaseResource>;
    isLoading: boolean
}

function TaxComponent({ data, isLoading }: Props) {
    const [selectedTaxCase, setSelectedTaxCase] = useState<TaxCaseResource | null>(null)
    const [openDrawer, setOpenDrawer] = useState(false)
    const mediumViewPort = useMedia("(max-width: 768px)");
    const [selectedView, setSelectedView] = useLocalStorage<"list" | "table">("dmf.taxDashboardDataView", "table")

    function setOpen(open: boolean) {
        if (open) {
            setOpenDrawer(true)
        } else {
            setOpenDrawer(false)

            // wait 500ms for the SlideOver to close before removing the selectedTaxCase
            // <TaxCasePreview/> displays <ContentLoading/> when selectedTaxCase == null
            setTimeout(() => setSelectedTaxCase(null), 500)

        }
    }

    return (
        <div className="md:p-8 p-4 relative">
            <TransitionChild>
                <div className="h-full flex flex-col max-w-body mx-auto rounded-sm md:bg-white md:shadow transition duration-500 data-[closed]:translate-y-24">
                    <div className="md:pl-5 md:pr-4 md:py-3 max-md:pb-3">
                        <TaxCaseListFilter mediumViewPort={mediumViewPort} selectedView={selectedView} setSelectedView={setSelectedView} />
                    </div>
                    <Spinner className='flex flex-col min-h-0' loading={isLoading}>
                        {(mediumViewPort || selectedView === 'list') ? (
                            <TaxCaseList
                                selectedTaxCase={selectedTaxCase}
                                setSelectedTaxCase={setSelectedTaxCase}
                                taxCases={data.data}
                                openDrawer={() => setOpenDrawer(true)}
                            />
                        ) : (
                            <TaxCaseTable
                                selectedTaxCase={selectedTaxCase}
                                setSelectedTaxCase={setSelectedTaxCase}
                                taxCases={data.data}
                                openDrawer={() => setOpenDrawer(true)}
                            />
                        )}

                    </Spinner>
                    <div className="md:pl-5 md:pr-4 py-3">
                        <PaginatedDataPaginationButtons pagination={data.meta} />
                    </div>
                </div>
            </TransitionChild>
            <BasicSlideOver size='lg' open={openDrawer} setOpen={setOpen} transitionDuration='300'>
                <TaxCasePreview taxCase={selectedTaxCase} />
            </BasicSlideOver>
        </div>
    )
}


type TaxCaseListFilterProps = {
    mediumViewPort: boolean;
    selectedView: "list" | "table";
    setSelectedView: React.Dispatch<React.SetStateAction<"list" | "table">>;

}



const dealerTaxStatuses = statuses.filter((status) => {
    return status.value === '1' // Aflyst
        || status.value === '2' // Udløbet
        || status.value === '3' // Gennemført
        || status.value === '4' // Accepteret
        || status.value === '5' // Forhåndsvurderet
        || status.value === '6' // Behandler
        || status.value === '7' // Nyt lead
})
dealerTaxStatuses.unshift({
    name: "Ingen",
    value: "NO_FILTER"
})

function TaxCaseListFilter({ mediumViewPort, selectedView, setSelectedView }: TaxCaseListFilterProps) {
    const navigate = useNavigate({ from: "/tax" })
    const query = useSearch({ from: "/_protected/_tax/tax/", select: (s) => s.q })
    const sort = useSearch({ from: "/_protected/_tax/tax/", select: (s) => s.sort })
    const initialSelectedFilterValue = useSearch({ from: "/_protected/_tax/tax/", select: (s) => s.status })
    const initialSelectedFilter = dealerTaxStatuses.find(status => status.value === String(initialSelectedFilterValue));
    const [selectedFilter, setSelectedFilter] = useState(initialSelectedFilter ? initialSelectedFilter : dealerTaxStatuses[0])

    function onSelectedFilterChange(v: typeof selectedFilter) {
        setSelectedFilter(v)
        navigate({ search: (prev) => ({ ...prev, page: 1, status: v.value === "NO_FILTER" ? undefined : Number(v.value) }) })
    }

    const debounceSearch = useCallback(
        debounce(async (e: ChangeEvent<HTMLInputElement>) => {
            await navigate({ search: (prev) => ({ ...prev, q: e.target.value, page: 1 }) });
        }, 500),
        []
    )

    return (
        <div className="flex-none flex gap-4 max-md:flex-col">
            <div className="flex-1 -ml-3">
                <label htmlFor="search" className="sr-only">
                    {t("search")}
                </label>
                <div className="relative w-full">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <Input
                        className="pl-10 h-10 w-full border-none text-sm data-[focus]:ring-0"
                        placeholder="Søg..."
                        type="search"
                        defaultValue={query}
                        onChange={debounceSearch}
                    />
                </div>
            </div>
            <div className='flex justify-end gap-2'>
                <Listbox value={selectedFilter} onChange={onSelectedFilterChange}>
                    <ListboxButton
                        className={clsx(
                            'relative block max-md:w-full min-w-44 rounded-sm bg-gray-100 h-10 pr-8 pl-4 text-left text-sm',
                            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-dark-blue'
                        )}
                    >
                        <span style={{ backgroundColor: "transparent" }}
                            className={clsx(
                                getStatusColorClassName(selectedFilter.value),
                                "inline-block mr-2 text-base",
                                selectedFilter.value === 'NO_FILTER' && "invisible"
                            )}
                        >&bull;</span>
                        {selectedFilter.name}
                        <ChevronDownIcon
                            className="group pointer-events-none absolute top-1/2 -translate-y-1/2 right-2.5 size-4 fill-white/60"
                            aria-hidden="true"
                        />
                    </ListboxButton>
                    <ListboxOptions
                        anchor={{ gap: 4, padding: 48, to: "bottom" }}
                        transition
                        className={clsx(
                            'w-[var(--button-width)] z-20 rounded-sm border bg-gray-50 p-1 focus:outline-none',
                            'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                        )}
                    >
                        {dealerTaxStatuses.map((status) => (
                            <ListboxOption
                                key={status.value}
                                value={status}
                                className="group flex cursor-default items-center gap-2 rounded-sm py-1.5 px-3 select-none data-[focus]:bg-gray-100 data-[selected]:bg-gray-100"
                            >
                                <span style={{ backgroundColor: "transparent" }}
                                    className={clsx(
                                        getStatusColorClassName(status.value),
                                        "inline-block",
                                        status.value === 'NO_FILTER' && "invisible"
                                    )}
                                >&bull;</span>
                                <div className="text-sm/6">{status.name}</div>
                                <CheckIcon className="invisible size-4 ml-auto fill-dark-blue group-data-[selected]:visible" />
                            </ListboxOption>
                        ))}
                    </ListboxOptions>
                </Listbox>
                <Link search={(s) => ({ ...s, sort: sort === 'id' ? "-id" : 'id' as "id" | "-id" })}
                    to="/tax"
                    className="flex-none p-0 btn btn-gray size-10 flex-col gap-0 group"
                    title={sort === 'id' ? "Sorter efter faldende sagsnummer" : "Sorter efter stigende sagsnummer"}
                    aria-label={sort === 'id' ? "Sorter efter faldende sagsnummer" : "Sorter efter stigende sagsnummer"}
                >
                    <ChevronUpIcon aria-hidden="true"
                        className={clsx(
                            "size-5 transition-opacity -mb-1",
                            sort !== 'id' && "opacity-50",
                            !sort || sort !== 'id' && "group-hover:opacity-100"
                        )}
                    />
                    <ChevronDownIcon aria-hidden="true"
                        className={clsx(
                            "size-5 transition-opacity",
                            sort !== '-id' && "opacity-50",
                            !!sort && sort !== '-id' && "group-hover:opacity-100"
                        )}
                    />
                </Link>
                {!mediumViewPort && (
                    <RadioGroup value={selectedView} onChange={setSelectedView}
                        className="p-0.5 h-10 flex-none bg-gray-100 self-start rounded-sm flex gap-1"
                    >
                        <Radio value="list"
                            className="px-2.5 h-full rounded-sm flex gap-1 items-center text-sm font-medium cursor-pointer select-none transition-colors text-text-secondary data-[checked]:bg-white data-[checked]:text-text-primary"
                        >
                            <ListBulletIcon className='size-5' aria-hidden="true" />
                            {t("list")}
                        </Radio>
                        <Radio value="table"
                            className="px-2.5 h-full rounded-sm flex gap-1 items-center text-sm font-medium cursor-pointer select-none transition-colors text-text-secondary data-[checked]:bg-white data-[checked]:text-text-primary"
                        >
                            <TableCellsIcon className='size-5' aria-hidden="true" />
                            {t("table")}
                        </Radio>
                    </RadioGroup>
                )}
                <Link className='max-lg:hidden btn btn-primary btn-lg text-sm gap-2' to="/tax/create">
                    <PlusIcon className="size-5" aria-hidden="true" />
                    {t("create")} {t("case_one")}
                </Link>
            </div>

        </div>
    )
}


function Statitics() {
    const { data, isFetching } = useDealerStatsQuery()
    if (!data) {
        return null
    }
    const general = data.data.general
    const tax = data.data.tax
    const isAllTime = Date.parse(data.meta.currentPeriod.from) < 0;

    return (
        <section className='bg-white border-t shadow relative'>
            {isFetching && (
                <div className={clsx(
                    "absolute inset-0 bg-white/75 flex justify-center items-center",
                )}>
                    <ArrowPathIcon className='size-10 text-primary animate-spin' />
                </div>
            )}
            <dl className='flex divide-x whitespace-nowrap overflow-auto snap-x snap-mandatory [&>*]:snap-always'>
                <div className='flex-1 basis-1/4 md:px-6 px-4 md:py-3 py-2 gap-4 flex items-center justify-between snap-start'>
                    <dt className='text-sm font-medium'>{t("clipUsage")}</dt>
                    <dd className="text-[17px] font-medium text-dark-blue">
                        {general.currentPeriod.creditsUsed}
                        {!isAllTime && (
                            <Stat num={general.currentPeriod.creditsUsed - general.previousPeriod.creditsUsed} />
                        )}
                    </dd>
                </div>
                <div className='flex-1 basis-1/4 md:px-6 px-4 md:py-3 py-2 gap-4 flex items-center justify-between snap-start'>
                    <dt className='text-sm font-medium'>{t("amountOfCasesCreated")}</dt>
                    <dd className="text-[17px] font-medium text-dark-blue">
                        {tax.currentPeriod.createdAmount}
                        {!isAllTime && (
                            <Stat num={tax.currentPeriod.createdAmount - tax.previousPeriod.createdAmount} />
                        )}
                    </dd>
                </div>
                <div className='flex-1 basis-1/4 md:px-6 px-4 md:py-3 py-2 gap-4 flex items-center justify-between snap-start'>
                    <dt className='text-sm font-medium'>{t("amountOfCasesAccepted")}</dt>
                    <dd className="text-[17px] font-medium text-dark-blue">
                        {tax.currentPeriod.acceptedAmount}
                        {!isAllTime && (
                            <Stat percent num={(tax.currentPeriod.acceptedAmount - tax.previousPeriod.acceptedAmount) / tax.previousPeriod.acceptedAmount} />
                        )}
                    </dd>
                </div>
                <div className='flex-1 basis-1/4 md:px-6 px-4 md:py-3 py-2 gap-4 flex items-center justify-between snap-end'>
                    <dt className='text-sm font-medium'>{t("yourPreferredBrand")}</dt>
                    <dd className="text-[17px] font-medium text-dark-blue text-right">
                        <div>
                            {tax.currentPeriod.mostRequestedBrand ?? "-"}
                        </div>
                        <div title={t("previousPeriod")} className='text-xs text-text-secondary'>
                            {tax.previousPeriod.mostRequestedBrand}
                        </div>
                    </dd>
                </div>
            </dl>
        </section>
    )
}
function Stat({ num, percent }: { num: number, percent?: boolean }) {
    const label = percent ? formatPercent(num) : num;
    return (
        <span title={t("previousPeriod")} className={clsx('text-xs font-medium align-top ml-2',
            num > 0
                ? "text-green-500"
                : num < 0
                    ? "text-red-500"
                    : "text-blue-500"
        )}>
            {num > 0 && "+"}
            {label}
        </span>
    )
}

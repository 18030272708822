import { errorMessage } from "@/utils/toast";
import { Field, Input, Label } from "@headlessui/react";
import { PhotoIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { t } from "i18next";
import { DragEventHandler, useRef, useState } from "react";

type FileDragAndDropProps = {
    name: string;
}
/**
 * onUploadFiles will dispatch an 'input' event that is meant to be captured on the parent form \
 * \<form onInput={onFileupload} >
 * \<form onInputCapture={onFileupload} >
 * \<div onInput={onFileupload} >
 *
 * onFileupload(e: FormEvent<HTMLDivElement>)
 */
export default function FileDragAndDrop({ name }: FileDragAndDropProps) {
    const [isDraggingFile, setIsDraggingFile] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null)

    function handleLabelClick() {
        fileInputRef.current?.click()
    }

    function onUploadFiles(file: File) {
        const mimeTypes = /^(image\/.*|application\/pdf)$/
        const filesObservingMIMEType = new DataTransfer();
        if (mimeTypes.test(file.type)) {
            if (fileInputRef.current?.files) {
                filesObservingMIMEType.items.add(file)
                fileInputRef.current.files = filesObservingMIMEType.files

                // This event dispatch is important, so that the parent form can pick up on the file being uploaded.
                fileInputRef.current.dispatchEvent(new Event('input', { bubbles: true }))
            }
        } else {
            errorMessage("Forkerte filtype: " + file.type + " - " + file.name)
        }
    }

    const onDragOver: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
    }
    const onDragEnter: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault()
        setIsDraggingFile(true)
    }
    const onDragLeave: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault()
        setIsDraggingFile(false)
    }
    const onDrop: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files
        if (files) {
            const [file] = files
            onUploadFiles(file)
        }
        setIsDraggingFile(false)
    }

    return (
        <Field className={clsx("rounded-sm overflow-hidden border border-dashed sm:px-8 px-4 sm:py-12 py-4 relative bg-gray-50",
            isDraggingFile ? 'border-blue-600' : "border-gray-900/25"
        )}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
        >
            <div className={clsx("text-center", isDraggingFile && "pointer-events-none")}>
                <Input
                    name={name}
                    type="file"
                    className="sr-only peer"
                    accept='image/*, application/pdf'
                    ref={fileInputRef}
                />
                <div className="text-xs">
                    <PhotoIcon className="size-6 text-primary inline-block -mt-0.5 mr-2" aria-hidden="true" />
                    <span>
                        Træk et billede her eller
                    </span>
                    {' '}
                    <Label
                        className="link text-xs cursor-pointer peer-focus:ring"
                        onClick={handleLabelClick}
                    >
                        {t("uploadAFile").toLowerCase()}
                    </Label>
                </div>
            </div>
        </Field>
    )
}

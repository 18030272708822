import { useLogin } from '@/hooks/useAuth';
import DMFLogo from "@assets/DMF-Logo/DMFPRO/Horizontal/dmf-white3x.png";
import FireworksVideo from "@assets/Fireworks-540.mp4";
import PoweredByDMFLogo from '@assets/images/powered-by-logo.png';
import { Button, Checkbox, Field, Fieldset, Input, Label } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import {
    Link,
    createFileRoute,
    useRouterState
} from '@tanstack/react-router';
import { t } from 'i18next';
import * as React from 'react';
import { z } from 'zod';
import { UnAuthenticatedLayout } from '../../components/UnauthenticatedLayout';
import { Credentials } from '../../types/auth';
import { errorMessage } from '../../utils/toast';

export const Route = createFileRoute('/_auth/login')({
    validateSearch: z.object({
        redirect: z.string().optional().catch(''),
    }),
    meta: () => [{ title: "Log ind" }],
    component: LoginComponent,
})

function LoginComponent() {

    const login = useLogin()
    const isLoading = useRouterState({ select: (s) => s.isLoading })
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const search = Route.useSearch()

    const onFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        setIsSubmitting(true)
        try {
            evt.preventDefault()
            const data = new FormData(evt.currentTarget)

            const credentials: Credentials = {
                email: data.get("email")?.toString() || '',
                password: data.get("password")?.toString() || '',
                remember: data.get("remember") === 'on',
            }

            await login(credentials, search.redirect)
        } catch (error) {
            errorMessage(error)
        } finally {
            setIsSubmitting(false)
        }

    }

    const isLoggingIn = isLoading || isSubmitting

    return (
        <UnAuthenticatedLayout>
            <video className='absolute top-0 left-0 w-screen h-screen opacity-15 saturate-200 brightness-75 contrast-125 object-cover -z-10 pointer-events-none'
                playsInline loop autoPlay preload='none' muted
            >
                <source src={FireworksVideo} type='video/mp4' />
            </video>

            <img
                className="mx-auto w-64 z-10 relative mb-12"
                src={DMFLogo}
                alt="DMF Pro"
            />

            <p className='mx-auto mb-8 w-full max-w-56 text-center text-white text-sm'>Log ind med din DMF konto fra danskmotorfinans.net</p>


            <div className="sm:mx-auto sm:w-full sm:max-w-md bg-white sm:px-12 sm:py-8 p-4 rounded-sm ">
                <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue">
                    Log ind til din konto
                </h2>
                {search.redirect && (
                    <p className="mt-2 text-center text-red-500">Du skal logge ind for at tilgå denne side.</p>
                )}
                <form className="mt-10 space-y-6" onSubmit={onFormSubmit}>
                    <Fieldset disabled={isLoggingIn} className="w-full grid gap-2">

                        <Field>
                            <Label className="input-label">
                                {t("email")}
                            </Label>
                            <Input
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="input mt-1"
                            />
                        </Field>

                        <Field>
                            <Label className="input-label">
                                {t("password")}
                            </Label>
                            <Input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="input mt-1"
                            />
                        </Field>

                        <div className="flex items-center justify-between mt-2 mb-4">
                            <Field className="flex items-center gap-2 text-base">
                                <Checkbox
                                    name="remember"
                                    className="group size-6 rounded-md bg-gray-200 p-1 ring-1 ring-gray-300 ring-inset data-[checked]:bg-white cursor-pointer"
                                >
                                    <CheckIcon className="hidden size-4 fill-black group-data-[checked]:block" />
                                </Checkbox>
                                <Label className="cursor-pointer text-sm">{t("rememberMe")}?</Label>
                            </Field>

                            <Link to='/forgot-password' className="link text-sm">
                                {t("forgotPassword")}
                            </Link>
                        </div>



                        <Button
                            type="submit"
                            className="btn btn-primary justify-center"
                        >
                            {isLoggingIn ? t('loading') : 'Login'}
                        </Button>
                    </Fieldset>
                </form>

                <p className="mt-10 text-center text-sm text-gray-500">
                    {t("content:login.Don't have access?")}{' '}
                    <Link to="/sign-up" className="link">
                        {t("createAsDealership")}
                    </Link>
                </p>

                <img src={PoweredByDMFLogo} className='mx-auto max-w-64 w-full mt-8' />
            </div>
        </UnAuthenticatedLayout>
    )
}

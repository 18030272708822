import StatusTag from "@/components/StatusTag"
import useFallbackCarLogo from "@/hooks/useFallbackCarLogo"
import { TaxCaseResource } from "@/types/taxTypes"
import { printMakeModelOrLink } from "@/utils/printHelpers"
import { formatDate, formatNumber, formatPrice } from "@/utils/string"
import { Button } from "@headlessui/react"
import clsx from "clsx"

type Props = {
    taxCases: TaxCaseResource[] | null;
    selectedTaxCase: TaxCaseResource | null;
    setSelectedTaxCase: (taxCase: TaxCaseResource) => void;
    openDrawer: () => void;
}
export default function TaxCaseList({ taxCases, selectedTaxCase, setSelectedTaxCase, openDrawer }: Props) {
    function onVehicleItemClick(taxCase: TaxCaseResource) {
        setSelectedTaxCase(taxCase)
        openDrawer()
    }
    return (
        <ul className="h-auto border-t">
            {taxCases?.map((item, i) => (
                <li key={item.id + i}>
                    <Button onClick={() => onVehicleItemClick(item)}
                        className={clsx(
                            selectedTaxCase && selectedTaxCase.id === item.id && "border-primary bg-primary/10",
                            "w-full flex gap-3 py-2 px-4 max-sm:px-2 cursor-pointer group transition-colors border-b hover:border-primary hover:bg-primary/5"
                        )}
                    >
                        <div className="flex flex-col justify-center items-center gap-1">
                            {item.vehicle?.image?.uri ? (
                                <div className="w-16 flex-none inline-flex items-center">
                                    <img className="w-full h-auto max-h-16 brightness-125" src={item.vehicle.image.uri} alt={item.vehicle.brand ?? ""} loading="lazy" />
                                </div>
                            ) : (
                                <CarLogo carBrand={item.vehicle?.brand ?? ""} />
                            )}
                            <span className="text-text-secondary text-xs transition-colors md:group-hover:text-primary-hover"># {item.id}</span>
                        </div>
                        <div className="text-left flex flex-col md:justify-center">
                            <span className="text-dark-blue font-semibold text-sm line-clamp-2">
                                {printMakeModelOrLink(item.vehicle?.brand, item.vehicle?.model, item.vehicle?.link)}
                            </span>
                            <span className="uppercase text-xs">{item.vehicle?.vin}</span>
                            <span className="text-xs">
                                <span className="whitespace-nowrap">{formatDate(item.vehicle?.firstRegDate)}</span> | {' '}
                                <span className="whitespace-nowrap">{formatNumber(item.vehicle?.mileage)} KM.</span>
                            </span>
                            {item.client && (
                                <span className="text-xs">{item.client?.name} | {formatDate(item.createdAt)}</span>
                            )}
                        </div>
                        <div className="flex-none flex flex-col justify-center items-end gap-2 text-right max-sm:order-2 ml-auto">
                            <span className="text-dark-blue-hover font-semibold text-sm tracking-tight">{formatPrice(item.assessment?.estimatedRegistrationFee, true)}</span>
                            <span className="">
                                <StatusTag name={item.status.name} value={item.status.value} />
                            </span>
                        </div>
                    </Button>
                </li>

            ))}
        </ul>
    )
}


function CarLogo({ carBrand }: { carBrand: string | undefined }) {
    const src = useFallbackCarLogo(carBrand);
    return (
        <div className="w-16 flex-none inline-flex items-center">
            <img className="w-full h-auto max-h-16" src={src} alt={carBrand} loading="lazy" />
        </div>

    )
}

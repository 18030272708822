import { createTaxCaseByLink, createTaxCaseByVin, deleteFileFromTaxCase, getTaxCase, getTaxCases, requestTaxCaseRecalculation } from "@/services/tax";
import { errorMessage, successMessage } from "@/utils/toast";
import { keepPreviousData, queryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import { t } from "i18next";

export const usePaginatedTaxCasesQuery = () => {
    const search = useSearch({ from: "/_protected/_tax/tax/" })
    return useQuery({
        queryKey: ['taxCases', search.page, search.per_page, search.q, search.status, search.sort],
        queryFn: async () => {
            const data = await getTaxCases(search.page, search.per_page, search.q, search.status, search.sort);
            return data.data;
        },
        placeholderData: keepPreviousData,
        staleTime: 0,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
    })
}

export const useTaxCaseQuery = (taxCaseId: string | number) => {
    return useQuery(getTaxCaseQueryOptions(taxCaseId))
}

export const getTaxCaseQueryOptions = (taxCaseId: string | number) => {
    return queryOptions({
        queryKey: ['taxCase', taxCaseId],
        queryFn: async () => {
            const data = await getTaxCase(taxCaseId);
            return data.data.data;
        },
        refetchOnMount: "always",
        retry: false,
    })
}


export const useCreateTaxCaseByVin = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (args: Parameters<typeof createTaxCaseByVin>) => await createTaxCaseByVin(...args),
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['taxCases'] })
            queryClient.invalidateQueries({ queryKey: ['user'] })
        },
        onError(error) {
            errorMessage(error)
        },
    })
}
export const useCreateTaxCaseByLink = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (args: Parameters<typeof createTaxCaseByLink>) => await createTaxCaseByLink(...args),
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['taxCases'] })
            queryClient.invalidateQueries({ queryKey: ['user'] })
        },
        onError(error) {
            errorMessage(error)
        },
    })
}

export const useDeleteFileFromTaxCase = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (args: Parameters<typeof deleteFileFromTaxCase>) => await deleteFileFromTaxCase(...args),
        onSuccess(_data, variables) {
            queryClient.invalidateQueries({ queryKey: ['taxCase', variables[0]] })
        },
        onError(error) {
            errorMessage(error)
        },
    })
}

export const useRequestRecalculationTaxCase = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (args: Parameters<typeof requestTaxCaseRecalculation>) => await requestTaxCaseRecalculation(...args),
        onSuccess(_data, variables) {
            successMessage(t("status:success.Recalculation request successful"))
            queryClient.invalidateQueries({ queryKey: ['taxCase', variables[0]] })
            queryClient.invalidateQueries({ queryKey: ['taxCases'] })
        },
        onError(error) {
            errorMessage(error)
        },
    })
}


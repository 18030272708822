import { CreateTaxCaseByLink, CreateTaxCaseByVin, TaxCaseResource } from '@/types/taxTypes'
import { ReactNode, createContext, useContext, useState } from 'react'
import { StoreApi, createStore, useStore } from 'zustand'


type State = {
    vinForm: CreateTaxCaseByVin | null
    linkForm: CreateTaxCaseByLink | null
    createdTaxCase: TaxCaseResource | null;
    creationMethod: "vin" | "link";
    actions: {
        setVinForm: (vinForm: CreateTaxCaseByVin) => void;
        setLinkForm: (vinForm: CreateTaxCaseByLink) => void;
        setCreatedTaxCase: (createdTaxCase: TaxCaseResource) => void;
        setCreationMethod: (creationMethod: "vin" | "link") => void;
    }
}

const TaxCaseContext = createContext<StoreApi<State> | null>(null)

type TaxCaseStoreProviderProps = {
    children: ReactNode;
    initialVinForm: CreateTaxCaseByVin | null;
    initialLinkForm: CreateTaxCaseByLink | null;
    initialCreationMethod: "vin" | "link";

}

export const TaxCaseCreationStoreProvider = ({ children, initialLinkForm, initialVinForm, initialCreationMethod }: TaxCaseStoreProviderProps) => {
    const [taxCaseCreationStore] = useState(() =>
        createStore<State>((set) => ({
            linkForm: initialLinkForm,
            vinForm: initialVinForm,
            createdTaxCase: null,
            creationMethod: initialCreationMethod,
            actions: {
                setLinkForm(linkForm) {
                    set((state) => ({
                        linkForm: {
                            ...state.linkForm,
                            ...linkForm
                        }
                    }))
                },
                setVinForm(vinForm) {
                    set((state) => ({
                        vinForm: {
                            ...state.linkForm,
                            ...vinForm
                        }
                    }))
                },
                setCreatedTaxCase(createdTaxCase) {
                    set(() => ({
                        createdTaxCase
                    }))
                },
                setCreationMethod(creationMethod) {
                    set({ creationMethod })
                },
            }
        }))
    )

    return (
        <TaxCaseContext.Provider value={taxCaseCreationStore}>
            {children}
        </TaxCaseContext.Provider>
    )
}



function useTaxCaseCreationStore(): State
function useTaxCaseCreationStore<T>(selector: (state: State) => T): T
function useTaxCaseCreationStore<T>(selector?: (state: State) => T) {
    const store = useContext(TaxCaseContext)
    if (!store) {
        throw new Error('Missing BearStoreProvider')
    }
    return useStore(store, selector!)
}

export const useTaxCaseCreation = () => useTaxCaseCreationStore()
export const useTaxCaseCreationActions = () => useTaxCaseCreationStore((state) => state.actions)
export const useTaxCaseCreationLinkForm = () => useTaxCaseCreationStore((state) => state.linkForm)
export const useTaxCaseCreationVinForm = () => useTaxCaseCreationStore((state) => state.vinForm)
export const useTaxCaseCreationMethod = () => useTaxCaseCreationStore((state) => state.creationMethod)
export const useTaxCaseCreationCreatedTaxCase = () => useTaxCaseCreationStore((state) => state.createdTaxCase)

import VinIcon from "@/assets/icons/VinIcon";
import DatePicker from "@/components/DatePicker";
import { Spinner } from "@/components/Spinner";
import Tooltip from "@/components/Tooltip";
import { companyConfig } from "@/config";
import { taxCaseEvaluationTypes, useCases } from "@/enums/enums";
import { useCreateTaxCaseByLink, useCreateTaxCaseByVin } from "@/hooks/useTax";
import { deleteTemporaryFile, getVehicleDataByVin, uploadTemporaryFileURL } from "@/services/hub";
import { TaxCaseCreationStoreProvider, useTaxCaseCreationActions, useTaxCaseCreationCreatedTaxCase, useTaxCaseCreationLinkForm, useTaxCaseCreationMethod, useTaxCaseCreationVinForm } from "@/stores/taxCaseCreationStore";
import { InterimFileResource, LinkResource } from "@/types/appTypes";
import { ReactFormSubmit } from "@/types/helperTypes";
import { CreateTaxCaseByLink, CreateTaxCaseByVin, TaxCaseResource } from "@/types/taxTypes";
import axios from "@/utils/axios";
import { formatPhone, validateVin, vinCheckRegex } from "@/utils/string";
import { errorMessage, successMessage } from "@/utils/toast";
import StartUpFigure from "@assets/figures/StartUp.png";
import LoadingIcon from '@assets/images/loading.svg';
import { Button, Field, Fieldset, Input, Label, Radio, RadioGroup, Select, Switch, Tab, TabGroup, TabList, TabPanel, TabPanels, Textarea } from "@headlessui/react";
import { ArrowDownTrayIcon, CheckCircleIcon, DocumentIcon, InformationCircleIcon, PhotoIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon as CheckCircleIconSolid } from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import { Link, useLoaderData, useNavigate, useRouter, useSearch } from "@tanstack/react-router";
import clsx from "clsx";
import { t } from "i18next";
import { ChangeEvent, FormEvent, useEffect, useId, useRef, useState } from "react";
import FileDragAndDrop from "../FileDragAndDrop";


const IS_NEW_VEHICLE_MILEAGE_LIMIT = 2000; // Not inclusive ==> mileage < 2000

function getInitialCaseStates(redirectValue: "cancel" | "success" | undefined) {
    if (redirectValue === "cancel") {
        return {
            step: 1,
            stepsCompleted: [0],
            caseCreated: false,
        }
    }

    if (redirectValue === 'success') {
        return {
            step: 2,
            stepsCompleted: [0, 1, 2],
            caseCreated: true,
        }
    }

    return {
        step: 0,
        stepsCompleted: [],
        caseCreated: false,
    }
}

export default function CreateTaxCase() {
    const search = useSearch({ from: "/_protected/_tax/tax/create" })
    const navigate = useNavigate({ from: "/tax/create" })
    const initialStates = getInitialCaseStates(search.value)

    const [step, setStep] = useState(initialStates.step);
    const [stepsCompleted, setStepsCompleted] = useState<number[]>(initialStates.stepsCompleted)
    const [caseCreated, setCaseCreated] = useState(initialStates.caseCreated)
    const [casesCreated, setCasesCreated] = useState(0);

    const goToNextStep = () => {
        setStep((step) => ++step)
        if (!stepsCompleted.includes(step)) {
            setStepsCompleted((prev) => [...prev, step])
            if (step === 1) {
                setStepsCompleted((prev) => [...prev, 2])
                setCaseCreated(true)
            }
        }

    }
    const renderDataOpenProps = (index: number) => ({ 'data-open': index === step ? "open" : null })
    const refreshPage = () => {
        navigate({ to: "/tax/create", search: () => ({}) })
        setTimeout(() => {
            setCasesCreated(n => ++n);
            setStepsCompleted([])
            setStep(0)
            setCaseCreated(false)
        }, 0)
    }
    const onCheckCircleClick = (step: number) => {
        if (stepsCompleted.includes(step)) {
            setStep(step)
        }
    }

    return (

        <TaxCaseCreationStoreProvider
            key={casesCreated}
            initialLinkForm={(search.additional && search.additional?.sourceValue && search.additional.sourceType === 1) ? {
                evaluationTypeId: search.additional.sourceValue.evaluationTypeId,
                files: [],
                interimFiles: search.additional.sourceValue.interimFiles ?? null,
                isPriority: false,
                note: search.additional.sourceValue.note ? String(search.additional.sourceValue.note).replace(/\+/g, " ") : "",
                vehicle: {
                    link: search.additional.sourceValue.vehicle.link,
                    vin: search.additional.sourceValue.vehicle.vin,
                },
                paymentMethod: search.additional.sourceValue?.paymentMethod,
            } : null}
            initialVinForm={(search.additional && search.additional?.sourceValue && search.additional.sourceType === 2) ? {
                evaluationTypeId: search.additional.sourceValue.evaluationTypeId,
                files: [],
                interimFiles: search.additional.sourceValue.interimFiles ?? null,
                isPriority: search.additional.sourceValue.isPriority,
                note: search.additional.sourceValue.note ? String(search.additional.sourceValue.note).replace(/\+/g, " ") : "",
                vehicle: {
                    vin: search.additional.sourceValue.vehicle.vin,
                    firstRegDate: search.additional.sourceValue.vehicle.firstRegDate,
                    mileage: search.additional.sourceValue.vehicle.mileage,
                    useCaseId: search.additional.sourceValue.vehicle.useCaseId,
                    isNew: search.additional.sourceValue.vehicle.isNew
                },
                paymentMethod: search.additional.sourceValue?.paymentMethod,
            } : null}
            initialCreationMethod={search.additional?.sourceType === 1 ? "link" : "vin"}
        >
            <div className="sm:p-8 p-4">
                <ul>
                    <li {...renderDataOpenProps(0)} className="group pb-8 border-b">
                        <div className="flex justify-between">
                            <h2 className="text-lg font-semibold text-text-secondary group-data-[open]:text-dark-blue">
                                1. {t("vehicleData")}
                            </h2>
                            <Button onClick={() => onCheckCircleClick(0)}
                                tabIndex={stepsCompleted.includes(0) ? 0 : -1}
                                className="p-1"
                                aria-label={t("goTo") + " step 1"}
                            >
                                {stepsCompleted.includes(0) ? (
                                    <CheckCircleIconSolid className="size-6 text-lime-500" />
                                ) : (
                                    <CheckCircleIcon className="size-6 text-text-secondary" />
                                )}
                            </Button>
                        </div>
                        <div className="transition-all duration-700 ease-in-out grid will-change-contents"
                            style={{
                                gridTemplateRows: step === 0 ? '1fr' : "0fr",
                                marginTop: step === 0 ? 32 : 0,
                            }}
                        >
                            <div className="px-0.5 overflow-hidden">
                                <CreateCaseFormTabs completed={caseCreated} goToNextStep={goToNextStep} />
                            </div>
                        </div>
                    </li>
                    <li {...renderDataOpenProps(1)} className="group py-8 border-b">
                        <div className="flex justify-between">
                            <h2 className="text-lg font-semibold text-text-secondary group-data-[open]:text-dark-blue">
                                2. {t("confirm")}
                            </h2>
                            <Button onClick={() => onCheckCircleClick(1)}
                                tabIndex={stepsCompleted.includes(1) ? 0 : -1}
                                className="p-1"
                                aria-label={t("goTo") + " step 2"}
                            >
                                {stepsCompleted.includes(1) ? (
                                    <CheckCircleIconSolid className="size-6 text-lime-500" />
                                ) : (
                                    <CheckCircleIcon className="size-6 text-text-secondary" />
                                )}
                            </Button>
                        </div>
                        <div className="transition-all duration-700 ease-in-out grid will-change-contents"
                            style={{
                                gridTemplateRows: step === 1 ? '1fr' : "0fr",
                                marginTop: step === 1 ? 32 : 0,
                            }}
                        >
                            <div className="overflow-hidden">
                                <ConfirmAndPay goToNextStep={goToNextStep} completed={caseCreated} />
                            </div>
                        </div>
                    </li>
                    <li {...renderDataOpenProps(2)} className="group pt-8">
                        <div className="flex justify-between">
                            <h2 className="text-lg font-semibold text-text-secondary group-data-[open]:text-dark-blue">
                                3. {t("case_one")} {t("created").toLowerCase()}
                            </h2>
                            <Button onClick={() => onCheckCircleClick(2)}
                                tabIndex={stepsCompleted.includes(2) ? 0 : -1}
                                className="p-1"
                                aria-label={t("goTo") + " step 3"}
                            >
                                {stepsCompleted.includes(2) ? (
                                    <CheckCircleIconSolid className="size-6 text-lime-500" />
                                ) : (
                                    <CheckCircleIcon className="size-6 text-text-secondary" />
                                )}
                            </Button>
                        </div>
                        <div className="transition-all duration-700 ease-in-out grid will-change-contents"
                            style={{
                                gridTemplateRows: step === 2 ? '1fr' : "0fr",
                                marginTop: step === 2 ? 32 : 0,
                            }}
                        >
                            <div className="overflow-hidden">
                                <CaseCreatedResult refreshPage={refreshPage} />
                            </div>
                        </div>
                    </li>
                </ul>
            </div >
        </TaxCaseCreationStoreProvider>

    )
}

function CaseCreatedResult({ refreshPage }: { refreshPage: () => void; }) {
    const createdTaxCase = useTaxCaseCreationCreatedTaxCase()
    const phone = companyConfig.phone.company
    const taxMail = companyConfig.emails.tax
    return (
        <div className="text-center">
            {createdTaxCase?.id && (
                <p className="text-xs text-text-secondary mb-2">
                    {t("case_one")} #<Link className="link" to="/tax/$id" params={{ id: createdTaxCase.id }}>{createdTaxCase?.id}</Link> {t("isCreated").toLowerCase()}
                </p>

            )}
            <h3 className="text-dark-blue text-2xl font-semibold ">
                {t("content:orderConfirmation.Thank you very much for your request")}
            </h3>
            <p className="text-sm mt-6">
                {t("content:orderConfirmation.Our experts will immediately start calculating the registration fee for you. On weekdays between 8-16 you can expect a response time within 30 minutes.")}
            </p>
            <img className="inline-block max-w-sm w-full" loading="lazy" src={StartUpFigure} alt="Start up" />
            <p className="max-w-72 mx-auto text-xs">{t("content:generic.Need faster expedition? Call us on")} <a href={`tel:${phone}`} className="link">{formatPhone(phone)}</a> eller på <a className="link" href={`mailto:${taxMail}`}>{taxMail}</a></p>
            <hr className="my-6" />
            <div className="flex flex-wrap gap-3">
                <Button onClick={refreshPage} className="btn btn-outline flex-none">
                    {t("createNewCase")}
                </Button>
                <Link to="/tax" search={{ page: 1 }} className="btn btn-outline flex-none">
                    {t("viewCaseArchive")}
                </Link>
                {createdTaxCase && (
                    <Link to="/tax/$id" params={{ id: createdTaxCase.id }} className="btn btn-primary flex-1">
                        {t("goTo")} {t("theCase").toLowerCase()} &rarr;
                    </Link>
                )}
            </div>
        </div>
    )
}



type SharedProps = {
    goToNextStep: () => void;
    completed?: boolean;
}
const paymentMethod = {
    clip: "1",
    card: "3",
    savedCard: "4",
} as const;
function ConfirmAndPay({ goToNextStep, completed }: SharedProps) {
    const search = useSearch({ from: "/_protected/_tax/tax/create" })
    const createbByVin = useCreateTaxCaseByVin()
    const createByLink = useCreateTaxCaseByLink()
    const { setCreatedTaxCase } = useTaxCaseCreationActions()
    const linkForm = useTaxCaseCreationLinkForm()
    const vinForm = useTaxCaseCreationVinForm()
    const creationMethod = useTaxCaseCreationMethod()
    const { user } = useLoaderData({ from: "/_protected" })
    const clipsLeft = Number(user?.credits.left)


    function getPrices() {
        const yellowToWhiteId = 3;
        let taxCaseClipPrice = user.prices.clip.tax.standard;
        let taxCaseCardPaymentPrice = user.prices.moneyNoVat.tax.standard;
        let evaluationTypeId = vinForm?.evaluationTypeId;
        if (creationMethod === 'link') evaluationTypeId = linkForm?.evaluationTypeId;
        if (evaluationTypeId === yellowToWhiteId) {
            taxCaseClipPrice = user.prices.clip.tax.yellowToWhite;
            taxCaseCardPaymentPrice = user.prices.moneyNoVat.tax.yellowToWhite;
        }
        return { taxCaseClipPrice, taxCaseCardPaymentPrice }
    }
    const { taxCaseClipPrice, taxCaseCardPaymentPrice } = getPrices()
    const hasEnoughClips = clipsLeft >= taxCaseClipPrice;
    const defaultPaymentMethod = search.additional?.sourceValue?.paymentMethod ? String(search.additional?.sourceValue?.paymentMethod) : hasEnoughClips ? paymentMethod.clip : paymentMethod.card;

    // const [termsAccepted, setTermsAccepted] = useState(true)
    // const onTermsChange = (checked: boolean) => setTermsAccepted(checked)
    const router = useRouter()
    async function onCreateCase(e: ReactFormSubmit) {
        e.preventDefault()
        const paymentMethod = getPaymentMethod(e.currentTarget, "paymentMethod")
        function successCallback(data: { data: { data: TaxCaseResource | LinkResource } }, paymentMethod: number | undefined) {
            router.invalidate()
            if (paymentMethod === 3) {
                const paymentLink = data.data.data as LinkResource;
                window.open(paymentLink.uri, "_self")
            } else {
                const taxCase = data.data.data as TaxCaseResource
                setCreatedTaxCase(taxCase)
                successMessage(t("status:success.taxCaseCreated", { taxCaseId: taxCase.id }))
                goToNextStep()
            }
        }
        if (creationMethod === 'link' && !!linkForm) {
            createByLink.mutate([{
                ...linkForm,
                paymentMethod: Number(paymentMethod)
            }], {
                onSuccess: (data, args) => successCallback(data, args[0].paymentMethod),
            })
        }
        if (creationMethod === 'vin' && !!vinForm) {
            createbByVin.mutate([{
                ...vinForm,
                paymentMethod: Number(paymentMethod)
            }], {
                onSuccess: (data, args) => successCallback(data, args[0].paymentMethod),
            })
        }
    }
    return (
        <Spinner loading={createbByVin.isPending || createByLink.isPending}>
            <form onSubmit={onCreateCase}>
                <Field className="sm:p-6 p-4 rounded-lg bg-gray-50">
                    <Label className="text-sm font-semibold text-dark-blue">{t("paymentMethods")}</Label>
                    <RadioGroup
                        name="paymentMethod"
                        defaultValue={defaultPaymentMethod}
                        className="mt-3 flex gap-3 max-md:flex-col"
                    >
                        {user.availablePaymentMethods.map(item => {
                            let title = t("voucher");
                            let description = `${clipsLeft} ${t("availableClips").toLowerCase()}`
                            let price = `${taxCaseClipPrice} ${t("clip", { count: taxCaseClipPrice })}`;
                            let disabled = !hasEnoughClips || completed

                            if (item.value === paymentMethod.card || item.value === paymentMethod.savedCard) {
                                title = item.value === paymentMethod.savedCard
                                    ? `${t("cardPayment")} - ${user.paymentMethodLastFour}`
                                    : t("cardPayment");
                                description = item.value === paymentMethod.savedCard
                                    ? t("useSavedCard")
                                    : `VISA, Mastercard, Dankort`
                                price = `Kr. ${taxCaseCardPaymentPrice},-`;
                                disabled = completed
                            }
                            return (
                                <Field key={item.value} className="flex-1">
                                    <Radio
                                        value={item.value}
                                        disabled={disabled}
                                        className="cursor-pointer block rounded-lg p-3 bg-white border border-dashed group data-[checked]:border-primary data-[disabled]:bg-gray-200"
                                    >
                                        <div className="flex justify-between items-center">
                                            <Label className="cursor-pointer text-dark-blue text-sm">{title}</Label>
                                            <span className="flex size-5 items-center justify-center rounded-full border group-data-[checked]:border-primary">
                                                <span className="invisible size-1 rounded-full bg-primary group-data-[checked]:visible" />
                                            </span>
                                        </div>
                                        <p className="text-text-secondary text-xs font-medium mt-2">{description}</p>
                                        <p className="text-dark-blue text-sm pt-5">{price}</p>
                                    </Radio>
                                </Field>
                            )
                        })}
                    </RadioGroup>
                </Field>
                {/* <Field className="flex items-center gap-2 flex-wrap col-span-full my-8" disabled={completed}>
                    <Checkbox
                        name="terms"
                        className="group flex-none size-6 cursor-pointer rounded-sm bg-gray-200 p-1 ring-1 ring-gray-300 ring-inset data-[checked]:bg-white data-[disabled]:bg-gray-300"
                        aria-required="true"
                        checked={termsAccepted}
                        onChange={onTermsChange}
                    >
                        <CheckIcon className="hidden flex-none size-4 fill-dark-blue group-data-[checked]:block" />
                    </Checkbox>
                    <Label className="flex-1 min-w-0 break-words cursor-pointer text-sm text-dark-blue data-[disabled]:opacity-50">
                        Jeg bekræfter at have læst og accepteret {' '}
                        <a onClick={e => e.stopPropagation()}
                            className="link" target="_blank"
                            href={companyConfig.links.businessTerms}
                        >“Forretningsbetingelser for værdifastsættelse af køretøjer”</a>
                    </Label>
                </Field> */}
                <Button type="submit"
                    disabled={completed}
                    className="btn btn-primary w-full btn-lg mt-8"
                >
                    {t("createCase")}
                </Button>
            </form>

        </Spinner>
    )
}


function CreateCaseFormTabs({ goToNextStep, completed }: SharedProps) {
    const search = useSearch({ from: "/_protected/_tax/tax/create" })
    const { setCreationMethod } = useTaxCaseCreationActions()
    const [selectedIndex, setSelectedIndex] = useState(search.additional?.sourceType === 1 ? 1 : 0)
    function onTabChange(index: number) {
        switch (index) {
            case 0: setCreationMethod('vin'); break;
            case 1: setCreationMethod('link'); break;
            default: setCreationMethod('vin');
        }
        setSelectedIndex(index)
    }

    return (
        <TabGroup onChange={onTabChange} selectedIndex={selectedIndex}>
            <TabList className="border-b mb-8">
                <Tab disabled={completed} className="tab-btn -mb-px">
                    {t("vin")}
                </Tab>
                <Tab disabled={completed} className="tab-btn -mb-px">
                    {t("link")}
                </Tab>
            </TabList>
            <TabPanels>
                <TabPanel unmount={false}>
                    <CreateByVin completed={completed} goToNextStep={goToNextStep} />
                </TabPanel>
                <TabPanel unmount={false}>
                    <CreateByLink completed={completed} goToNextStep={goToNextStep} />
                </TabPanel>
            </TabPanels>
        </TabGroup>
    )
}

function CreateByVin({ goToNextStep, completed }: SharedProps) {
    const search = useSearch({ from: "/_protected/_tax/tax/create" })
    const sourceValue = search.additional?.sourceValue as CreateTaxCaseByVin | undefined
    const { setVinForm } = useTaxCaseCreationActions()
    const [vin, setVin] = useState(sourceValue?.vehicle.vin as string || "")
    const [firstRegDate, setFirstRegDate] = useState<Date | null>(sourceValue?.vehicle?.firstRegDate ? new Date(sourceValue.vehicle.firstRegDate) : null)
    const [mileage, setMileage] = useState<string | number>(sourceValue?.vehicle?.mileage || '')
    const [files, setFiles] = useState<InterimFileResource[]>(search?.additional?.sourceValue?.interimFiles ?? [])

    const { data: vinData, isLoading } = useQuery({
        queryKey: ["DMRVinData", vin],
        queryFn: async () => {
            const data = await getVehicleDataByVin(vin)
            const vinData = data.data.data;
            return vinData
        },
        enabled: !!validateVin(vin),
        retry: false,
        staleTime: Infinity,
        placeholderData: !sourceValue ? undefined : {
            firstRegDate: (sourceValue?.vehicle?.firstRegDate as string) || null,
            make: null,
            mileage: sourceValue?.vehicle?.mileage || null,
            model: null,
            numberPlates: null,
            type: null,
            variant: null,
            vin: sourceValue?.vehicle?.vin || null
        },
    })

    const readyToContinue = validateVin(vin) && firstRegDate && mileage;

    const isNewVehicleId = useId()
    const isPriorityId = useId()
    const useCaseId = useId()

    const [isNewVehicle, setIsNewVehicle] = useState(false)
    const [isPriority, setIsPriority] = useState(!!sourceValue?.isPriority)
    const onIsNewVehicleChange = (checked: boolean) => setIsNewVehicle(checked)
    const onPriorityChange = (checked: boolean) => setIsPriority(checked)
    const onMileageChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMileage(e.target.value && Number(e.target.value))
    }

    useEffect(() => {
        if (mileage && Number(mileage) < IS_NEW_VEHICLE_MILEAGE_LIMIT && !vinData?.numberPlates && validateVin(vin)) {
            setIsNewVehicle(true);
        } else {
            setIsNewVehicle(false)
        }
    }, [mileage, vinData, vin])

    useEffect(() => {
        const hasFirstRegDateAlreadyInVerySpecialCase = !vinData?.firstRegDate && !!sourceValue?.vehicle?.firstRegDate
        if (vinData?.firstRegDate) {
            setFirstRegDate(new Date(vinData.firstRegDate))
        } else if (!firstRegDate) {
            !hasFirstRegDateAlreadyInVerySpecialCase && setFirstRegDate(null)
        }
    }, [vinData])

    const setFormValues = async (e: ReactFormSubmit) => {
        try {
            e.preventDefault()
            const data = new FormData(e.currentTarget)
            // const files = data.getAll('files') as File[]
            // const base64EncodedFileList = await fileListToBase64LinkResource(files)
            goToNextStep()
            setVinForm({
                evaluationTypeId: parseInt(data.get("calculationType")?.toString() || taxCaseEvaluationTypes[0].value),
                files: [],
                interimFiles: files,
                isPriority: isPriority,
                note: data.get("note")?.toString() || '',
                vehicle: {
                    isNew: isNewVehicle,
                    useCaseId: Number(data.get("useCase")?.toString() ?? 1),
                    mileage: Number(data.get("mileage") || '0'),
                    vin,
                    firstRegDate: firstRegDate ? firstRegDate.toISOString() : null
                },
            })
        } catch (error) {
            errorMessage(error)
        }

    }

    return (

        <form onSubmit={setFormValues}>
            <div className="space-y-4">
                <Fieldset disabled={completed}>
                    <EvaluationTypeRadioGroup />
                </Fieldset>

                <div className="bg-gray-50 rounded-lg sm:p-6 p-4 space-y-2">
                    <InputVin required setVin={setVin} vin={vin} disabled={completed} />
                    <div className="grid sm:grid-cols-2 gap-2 ">
                        <DatePicker selectedDate={firstRegDate} setSelectedDate={setFirstRegDate} />

                        <Field disabled={completed} className="relative">
                            <Label className="input-label-inset">{t("mileage")} *</Label>
                            <Input
                                type="number"
                                name="mileage"
                                value={mileage}
                                onChange={onMileageChange}
                                required
                                className="input-inset"
                            />
                        </Field>
                    </div>

                    {vinData?.firstRegDate && (
                        <div className="p-4 border rounded-lg relative">
                            {isLoading && (
                                <div className="absolute inset-0 bg-white/50">
                                    <img src={LoadingIcon} className="size-10 relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                                </div>
                            )}
                            <dl className="flex flex-wrap gap-2 justify-between ">
                                <div>
                                    <dt className="text-[11px]/5 mb-1 font-medium text-text-secondary">{t("makeModelAndVariant")}</dt>
                                    <dd className="text-sm font-medium text-dark-blue">
                                        {vinData?.make} {' '}
                                        {vinData?.model} {' '}
                                        {vinData?.variant}
                                    </dd>
                                </div>
                                <div>
                                    <dt className="text-[11px]/5 mb-1 font-medium text-text-secondary">{t("vin")}</dt>
                                    <dd className="text-sm font-medium text-dark-blue">{vinData?.vin}</dd>
                                </div>
                                <div>
                                    <dt className="text-[11px]/5 mb-1 font-medium text-text-secondary">{t("registrationNumber")}</dt>
                                    <dd className="text-sm font-medium text-dark-blue">{vinData?.numberPlates}</dd>
                                </div>
                                <div>
                                    <dt className="text-[11px]/5 mb-1 font-medium text-text-secondary">{t("taxGroup")}</dt>
                                    <dd className="text-sm font-medium text-dark-blue">{vinData?.type}</dd>
                                </div>
                            </dl>
                            <p className="text-right text-text-secondary text-xs italic mt-4">Opslag fra Motorregister</p>
                        </div>
                    )}

                </div>


                <Field disabled={completed} className={clsx(
                    "p-2.5 border flex-1 border-gray-300 border-dashed rounded-lg flex items-start gap-3 data-[disabled]:bg-gray-200 data-[disabled]:opacity-50",
                    !isNewVehicle && "sr-only"
                )}>
                    <Label className="text-sm text-dark-blue font-semibold whitespace-nowrap mr-auto" aria-describedby={isNewVehicleId}>
                        {t("shouldVehicleBeCalculatedAsNew")}?
                    </Label>
                    <Tooltip body={t("content:carIsNewCondition")} btn={
                        <span id={isNewVehicleId} className="pointer-events-none" aria-label={t("content:carIsNewCondition")}>
                            <InformationCircleIcon className="size-5" />
                        </span>
                    } />

                    <Switch disabled
                        checked={isNewVehicle}
                        onChange={onIsNewVehicleChange}
                        name="priority"
                        className={clsx(
                            "group relative opacity-20 cursor-not-allowed flex items-center h-5 w-9 rounded-full border border-primary transition-colors duration-200 ease-in-out",
                            "focus:outline-none data-[focus]:outline-1 data-[focus]:outline-primary data-[checked]:bg-primary/25")}
                    >
                        <span
                            aria-hidden="true"
                            className="pointer-events-none inline-block size-4 rounded-full  ring-0 shadow-lg transition duration-200 ease-in-out translate-x-0.5 bg-primary group-data-[checked]:bg-primary-active group-data-[checked]:translate-x-4"
                        />
                    </Switch>
                </Field>
                <div className="grid sm:grid-cols-2 gap-2">
                    <div className="flex flex-col gap-2">
                        <Field disabled={completed} className="p-2.5 border w-full border-gray-300 border-dashed rounded-md flex items-start gap-3 data-[disabled]:bg-gray-200 data-[disabled]:opacity-50">
                            <Label className="text-sm text-dark-blue font-semibold whitespace-nowrap mr-auto" aria-describedby={isPriorityId}>
                                {t("highPriority")}
                            </Label>
                            <Tooltip body={t("content:high_priority_fx_car_is_sold")} btn={
                                <span id={isPriorityId} aria-label={t("content:high_priority_fx_car_is_sold")}
                                >
                                    <InformationCircleIcon className="size-5" />
                                </span>
                            } />
                            <Switch
                                checked={isPriority}
                                onChange={onPriorityChange}
                                name="priority"
                                className={clsx(
                                    "group relative flex items-center h-5 w-9 cursor-pointer rounded-full border border-primary transition-colors duration-200 ease-in-out",
                                    "focus:outline-none data-[focus]:outline-1 data-[focus]:outline-primary data-[checked]:bg-primary/25")}
                            >
                                <span
                                    aria-hidden="true"
                                    className="pointer-events-none inline-block size-4 rounded-full  ring-0 shadow-lg transition duration-200 ease-in-out translate-x-0.5 bg-primary group-data-[checked]:bg-primary-active group-data-[checked]:translate-x-4"
                                />
                            </Switch>
                        </Field>
                        <Field disabled={completed} className="p-2.5 border w-full border-gray-300 border-dashed rounded-md data-[disabled]:bg-gray-200 data-[disabled]:opacity-50">
                            <div className="flex items-start gap-3 mb-2">
                                <Label className="text-sm text-dark-blue font-semibold whitespace-nowrap mr-auto" aria-describedby={useCaseId}>
                                    {t("specialUse")}
                                </Label>
                                <Tooltip body={t("content:hasVehicleBeenUsedAsTaxiPoilceMilitaryEmergecyOrRental")} btn={
                                    <span id={useCaseId} aria-label={t("content:hasVehicleBeenUsedAsTaxiPoilceMilitaryEmergecyOrRental")}
                                    >
                                        <InformationCircleIcon className="size-5" />
                                    </span>
                                } />
                            </div>
                            <Select
                                name="useCase"
                                defaultValue={sourceValue?.vehicle?.useCaseId ? String(sourceValue?.vehicle.useCaseId) : "1"}
                                className={clsx("w-full rounded-sm text-sm border-none bg-gray-50 transition-colors duration-200")}
                            >
                                {useCases.slice(1).map((useCase) => (
                                    <option key={useCase.value} value={useCase.value}>{useCase.name}</option>
                                ))}
                            </Select>
                        </Field>
                        <Field disabled={completed} className="relative w-full flex-1">
                            <Label className="input-label-inset">{t("remarks")}</Label>
                            <Textarea
                                placeholder="Indsæt dine eventuelle bemærkninger her..."
                                name="note"
                                rows={3}
                                defaultValue={sourceValue?.note ? String(sourceValue.note).replace(/\+/g, " ") : ""}
                                className="input-inset h-full"
                            />
                        </Field>
                    </div>
                    <FileUpload files={files} setFiles={setFiles} />

                </div>


                <Button
                    type="submit"
                    disabled={!readyToContinue && !completed}
                    className="btn btn-primary w-full btn-lg mt-4"
                >
                    {t("continue")}
                </Button>
            </div>
        </form>
    )
}

function checkUrl(string: string) {
    try {
        new URL(string);
    } catch (error) {
        return false;
    }
    return true;
}
function CreateByLink({ goToNextStep, completed }: SharedProps) {
    const search = useSearch({ from: "/_protected/_tax/tax/create" })
    const sourceValue = search.additional?.sourceValue as CreateTaxCaseByLink | undefined
    const { setLinkForm } = useTaxCaseCreationActions()
    const [link, setLink] = useState(sourceValue?.vehicle?.link || "")
    const [vin, setVin] = useState(sourceValue?.vehicle?.vin || "")
    const [files, setFiles] = useState<InterimFileResource[]>(search?.additional?.sourceValue?.interimFiles ?? [])

    const onLinkChange = (e: ChangeEvent<HTMLInputElement>) => setLink(e.target.value)

    const canContinue = checkUrl(link) && !completed;

    const setFormValues = async (e: ReactFormSubmit) => {
        try {
            e.preventDefault()
            const data = new FormData(e.currentTarget)
            // const files = data.getAll('files') as File[]
            // const base64EncodedFileList = await fileListToBase64LinkResource(files)

            goToNextStep()
            setLinkForm({
                evaluationTypeId: parseInt(data.get("calculationType")?.toString() || taxCaseEvaluationTypes[0].value),
                // files: base64EncodedFileList,
                files: [],
                interimFiles: files,
                isPriority: false,
                note: data.get("note")?.toString() || '',
                vehicle: {
                    link,
                    vin,
                }
            })
        } catch (error) {
            errorMessage(error)
        }

    }

    return (
        <form onSubmit={setFormValues}>
            <Fieldset disabled={completed} className="space-y-4">
                <EvaluationTypeRadioGroup />

                <div className="bg-gray-50 rounded-lg p-6 space-y-2">
                    <Field className="relative">
                        <Label className="input-label-inset">{t("link")} *</Label>
                        <Input
                            type="text"
                            name="link"
                            value={link}
                            onChange={onLinkChange}
                            required
                            placeholder="https://suchen.mobile.de/fahrzeuge/details.html?id=395629289&scopeId=C&action=parkItem&vc=Car&s=Car"
                            className="input-inset"
                        />
                    </Field>
                    <InputVin vin={vin} setVin={setVin} />
                </div>

                <div className="grid sm:grid-cols-2 gap-2">
                    <Field className="relative w-full flex-1">
                        <Label className="input-label-inset">{t("remarks")}</Label>
                        <Textarea
                            placeholder="Indsæt dine eventuelle bemærkninger her..."
                            name="note"
                            rows={4}
                            defaultValue={sourceValue?.note ? String(sourceValue.note).replace(/\+/g, " ") : ""}
                            className="input-inset h-full"
                        />
                    </Field>

                    <FileUpload files={files} setFiles={setFiles} />
                </div>

                <Button type="submit"
                    disabled={!canContinue}
                    className="btn btn-primary w-full btn-lg mt-4"
                >
                    {t("continue")}
                </Button>
            </Fieldset>
        </form>
    )
}



function EvaluationTypeRadioGroup() {
    const search = useSearch({ from: "/_protected/_tax/tax/create" })
    return (
        <Field className="sm:p-6 p-4 rounded-md border border-dashed border-gray-300 data-[disabled]:bg-gray-200">
            <Label className="text-sm font-semibold text-dark-blue">{t("plateType")}</Label>
            <RadioGroup
                name="calculationType"
                defaultValue={search.additional?.sourceValue?.evaluationTypeId || Number(taxCaseEvaluationTypes[1].value)}
                className="mt-1 flex gap-3 flex-wrap max-md:flex-col"
            >
                {taxCaseEvaluationTypes.map((item) => {
                    if (item.value === "127") {
                        return null;
                    }
                    return (
                        <Field className="flex-1" key={item.value}>
                            <Radio
                                value={Number(item.value)}
                                className="flex items-center gap-2 cursor-pointer rounded-sm p-2 border border-dashed border-gray-300 group transition-colors data-[checked]:border-primary"
                            >
                                <span className="flex size-4 items-center justify-center rounded-full border  transition-colors group-data-[checked]:border-primary">
                                    <span className="size-1 rounded-full transition-opacity bg-primary opacity-0 group-data-[checked]:opacity-100" />
                                </span>
                                <Label className="cursor-pointer text-dark-blue text-base">{item.name}</Label>
                            </Radio>
                        </Field>
                    )
                }
                )}
            </RadioGroup>
        </Field>
    )
}

type InputVinProps = {
    required?: boolean,
    vin: string,
    setVin: React.Dispatch<React.SetStateAction<string>>;
    disabled?: boolean;
}
function InputVin({ required, setVin, vin, disabled }: InputVinProps) {
    const vinWrapperRef = useRef<HTMLDivElement>(null)
    function onVinChange(e: ChangeEvent<HTMLInputElement>) {
        const uppercaseVin = e.target.value.toUpperCase()
        setVin(uppercaseVin)
        const isValidVin = validateVin(uppercaseVin)
        if (!vinWrapperRef.current) {
            return;
        }
        vinWrapperRef.current.setAttribute("data-vin-length", String(e.target.value.length))
        if (isValidVin) {
            vinWrapperRef.current.setAttribute("data-vin-valid", '')
        } else {
            vinWrapperRef.current.removeAttribute("data-vin-valid")
        }
    }
    return (
        <Field disabled={disabled} className="relative">
            <Label className="input-label-inset">
                {t("vin")} {required && "*"}
            </Label>
            <Input
                type="text"
                name="vin"
                className="input-inset pr-12  "
                pattern={vinCheckRegex.toString().replaceAll('/', '')}
                maxLength={17}
                value={vin}
                onChange={onVinChange}
                required={required}
            />
            <div ref={vinWrapperRef}
                title={t("vinFormat")}
                className="group absolute inset-y-0 right-4 text-base font-medium flex items-center before:content-[attr(data-vin-length)] before:mr-2 before:mt-0.5 text-red-500 data-[vin-valid]:text-primary">
                <VinIcon />
            </div>
        </Field>
    )
}

function getPaymentMethod(form: HTMLFormElement, namedItem: string) {
    const paymentMethod = form.elements.namedItem(namedItem)
    if (paymentMethod instanceof HTMLInputElement) {
        return paymentMethod.value as "1" | "3" | "4"
    }
    return ""
}


type FileUploadProps = {
    files: InterimFileResource[];
    setFiles: React.Dispatch<React.SetStateAction<InterimFileResource[]>>;
}
function FileUpload({ files, setFiles }: FileUploadProps) {
    const [isDeleting, setIsDeleting] = useState(false);
    const [uploadPercent, setUploadedPercent] = useState<null | number>(null)
    const url = uploadTemporaryFileURL();
    async function onFileupload(e: FormEvent<HTMLDivElement>) {
        // e.preventDefault();
        if ('files' in e.target && e.target.files) {
            try {
                const formData = new FormData()
                const [file] = e.target.files as FileList;
                formData.set("attachment", file)
                const { data } = await axios.post<{ data: InterimFileResource }>(url, formData, {
                    onUploadProgress(progressEvent) {
                        if (progressEvent.loaded && progressEvent.total) {
                            setUploadedPercent(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                        }
                    },
                });
                setFiles((prev) => ([data.data, ...prev]))
            } catch (error) {
                errorMessage(error)
            } finally {
                setUploadedPercent(null)
            }

        }
    }
    async function onFileDelete(mediaUUID: string) {
        try {
            setIsDeleting(true)
            await deleteTemporaryFile(mediaUUID)
            setFiles((prev) => prev.filter(file => file.id !== mediaUUID))
        } catch (error) {
            errorMessage(error)
        } finally {
            setIsDeleting(false)
        }
    }
    return (
        <div>
            <div
                onInputCapture={onFileupload}
                className={clsx(uploadPercent && "pointer-events-none")}
            >
                <FileDragAndDrop name='files' />
                {uploadPercent && (
                    <>
                        <progress className='sr-only' value={uploadPercent} max={100}></progress>
                        <p className='text-sm mb-1 mt-4 font-semibold text-dark-blue'>{uploadPercent}%</p>
                        <div className="w-full bg-gray-200 rounded-full h-2.5 overflow-clip">
                            <div className="bg-primary h-2.5 rounded-full w-full transition-transform -translate-x-full" style={{
                                transform: `translateX(${uploadPercent - 100}%)`,
                            }}></div>
                        </div>
                    </>
                )}
            </div>
            <Spinner loading={isDeleting}>
                <ul className="space-y-2 mt-4">
                    {files && files.map((media, i) => media && (
                        <li key={i} className="bg-gray-50 p-4 rounded-sm flex gap-2 items-center">
                            {renderFileExtensionMediaIcon(media.name)}
                            <span className="text-xs font-medium truncate">{media.name}</span>
                            <Button className="ml-auto flex-none rounded-full bg-red-100 text-red-500 size-8 flex items-center justify-center"
                                aria-label={t("delete") + " " + media.name}
                                disabled={isDeleting}
                                onClick={() => onFileDelete(media.id)}
                            >
                                <TrashIcon className="size-5" aria-hidden="true" />
                            </Button>
                            <a className="rounded-full flex-none bg-primary/10 text-primary size-8 flex items-center justify-center"
                                aria-label={"Download " + media.name}
                                href={media.uri}
                                target="_blank"
                                download={media.name}
                            >
                                <ArrowDownTrayIcon className="size-5" aria-hidden="true" />
                            </a>
                        </li>
                    ))}
                </ul>
            </Spinner>
        </div>
    )
}
function renderFileExtensionMediaIcon(filename: string) {
    if (/(\.png|\.jpg|\.jpeg|\.jfif|\.pjpeg|\.pjp|\.avif|\.gif|\.webp)$/i.test(filename)) {
        return <PhotoIcon className='size-5' aria-hidden="true" />
    }
    return <DocumentIcon className="size-5" aria-hidden="true" />
}


// type FileDragAndDropProps = {
//     name: string;
//     wrapperClassName?: string;
//     acceptedMimeTypes?: RegExp;
// }

// TODO --> Should files be uploaded here?
// TODO --> MAX UPLOAD SIZE
// Cumulative or file-by-file?
// function FileDragAndDrop({ name, wrapperClassName, acceptedMimeTypes }: FileDragAndDropProps) {
//     const [isDraggingFile, setIsDraggingFile] = useState(false);
//     const [files, setFiles] = useState<FileList | null>(null);
//     const fileInputRef = useRef<HTMLInputElement>(null)

//     useEffect(() => {
//         if (files && fileInputRef.current?.files) {
//             fileInputRef.current.files = files
//         }
//     }, [files])

//     function handleLabelClick() {
//         fileInputRef.current?.click()
//     }

//     function onUploadFiles(files: FileList) {
//         const mimeTypes = acceptedMimeTypes || /^(image\/.*|application\/pdf)$/
//         const filesObservingMIMEType = new DataTransfer();
//         let filesNotObservingMIMEType = '';
//         Array.from(files).forEach(file => {
//             if (mimeTypes.test(file.type)) {
//                 filesObservingMIMEType.items.add(file)
//             } else {
//                 filesNotObservingMIMEType += file.type + ": " + file.name + "\n";
//             }
//         })

//         setFiles(filesObservingMIMEType.files)
//         if (filesNotObservingMIMEType.length) {
//             errorMessage("Forkerte filtyper \n" + filesNotObservingMIMEType)
//         }
//     }

//     function onFileChange(e: ChangeEvent<HTMLInputElement>) {
//         const files = e.currentTarget.files;
//         if (files) {
//             onUploadFiles(files)
//         }
//     }
//     function deleteFile(index: number) {
//         const inputFiles = fileInputRef.current?.files;
//         if (!inputFiles) return;
//         const newFiles = Array.from(inputFiles)
//             .reduce((dataTransfer, file, i) => {
//                 if (i !== index) {
//                     dataTransfer.items.add(file)
//                 }
//                 return dataTransfer;
//             }, new DataTransfer())
//             .files

//         setFiles(newFiles.length ? newFiles : null);
//         fileInputRef.current.files = newFiles;
//     }
//     function getDownloadLinkOfUploadedFile(file: File) {
//         return URL.createObjectURL(file)
//     }

//     const onDragOver: DragEventHandler<HTMLDivElement> = (e) => {
//         e.preventDefault();
//     }
//     const onDragEnter: DragEventHandler<HTMLDivElement> = (e) => {
//         e.preventDefault()
//         setIsDraggingFile(true)
//     }
//     const onDragLeave: DragEventHandler<HTMLDivElement> = (e) => {
//         e.preventDefault()
//         setIsDraggingFile(false)
//     }
//     const onDrop: DragEventHandler<HTMLDivElement> = (e) => {
//         e.preventDefault();
//         const files = e.dataTransfer.files
//         if (files) {
//             onUploadFiles(files)
//         }
//         setIsDraggingFile(false)
//     }

//     return (
//         <Field className={wrapperClassName}>
//             <div className={clsx("rounded-lg overflow-hidden border border-dashed p-4 relative space-y-4",
//                 isDraggingFile ? 'border-blue-600' : "border-gray-900/25"
//             )}
//                 onDragOver={onDragOver}
//                 onDragEnter={onDragEnter}
//                 onDragLeave={onDragLeave}
//                 onDrop={onDrop}
//             >
//                 {files && (
//                     <ul className={clsx("text-right md:space-y-1 space-y-2", isDraggingFile && "pointer-events-none")}>
//                         {Array.from(files).map((file, index) => {
//                             const fileSizeText = formatFileSize(file.size);
//                             return (
//                                 <li className="text-xs text-text-secondary space-x-2" key={file.name + index}>
//                                     <code className="font-medium leading-6 inline-block align-middle">
//                                         {file.name}
//                                     </code>
//                                     <code className="font-semibold leading-6 inline-block align-middle">
//                                         {fileSizeText}
//                                     </code>
//                                     <Button
//                                         aria-label={t("get")}
//                                         tabIndex={-1}
//                                         className="btn btn-text align-middle h-6 px-1"
//                                     >
//                                         <a href={getDownloadLinkOfUploadedFile(file)} download={file.name}>
//                                             <ArrowDownTrayIcon className="size-5 leading-5" aria-hidden="true" />
//                                         </a>
//                                     </Button>
//                                     <Button onClick={() => deleteFile(index)}
//                                         aria-label={t("delete")}
//                                         className="btn btn-text align-middle h-6 px-1 danger"
//                                     >
//                                         <TrashIcon className="size-5 leading-5" aria-hidden="true" />
//                                     </Button>
//                                 </li>
//                             )
//                         }
//                         )}
//                     </ul>
//                 )}
//                 <p className={clsx("text-xs text-center leading-5 text-gray-600 bg-white/75", isDraggingFile && "pointer-events-none")}>
//                     {t("dragAndDropZone")} &mdash; {t("image")}, PDF {t('upTo').toLowerCase()} 10MB
//                 </p>
//             </div>
//             <div className={clsx("text-right mt-4")}>
//                 <Input
//                     name={name}
//                     type="file"
//                     className="sr-only peer"
//                     accept='image/*, application/pdf'
//                     multiple
//                     ref={fileInputRef}
//                     onChange={onFileChange}
//                 />
//                 <Label
//                     className="relative btn btn-outline cursor-pointer peer-focus:ring"
//                     onClick={handleLabelClick}
//                 >
//                     <span>{t("uploadAFile")}</span>
//                 </Label>
//             </div>
//         </Field>
//     )
// }

import ContentLoading from '@/components/ContentLoading'
import { companyConfig, subscriptionConfig } from '@/config'
import { checkIsAdmin } from '@/enums/enums'
import DashboardCard from '@/features/dashboard-cards/DashboardCard'
import EditDealerUser from '@/features/dealer/EditDealerUser'
import useFallbackCarLogo from '@/hooks/useFallbackCarLogo'
import { printMakeModelOrLink } from '@/utils/printHelpers'
import { Button, Dialog, DialogBackdrop, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import { AtSymbolIcon, ChartBarIcon, ClockIcon, CreditCardIcon, DocumentMagnifyingGlassIcon, PencilIcon, PhoneIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { queryOptions, useQuery } from '@tanstack/react-query'
import { CatchBoundary, Link, createFileRoute, useLoaderData } from '@tanstack/react-router'
import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import Avatar from '../../components/Avatar'
import StatusTag from '../../components/StatusTag'
import InviteNewUser from '../../features/dealer/InviteNewUser'
import { paginatedUsersQueryOptions } from '../../hooks/useDealer'
import { getDashboardData } from '../../services/hub'
import { DashboardData, EnumItem, PaginatedData } from '../../types/appTypes'
import { UserResource } from '../../types/auth'
import { formatDate, formatNumber, formatPhone, formatTime } from '../../utils/string'

const dashboardDataQueryoptions = () => queryOptions({
    queryKey: ['dashboardData'],
    queryFn: async () => (await getDashboardData()).data,
    staleTime: 0,
    retry: false,
})

export const Route = createFileRoute('/_protected/dashboard')({
    component: DashboardWrapper,
    meta: () => [{ title: t("dashboard") }],
})

function DashboardWrapper() {

    const [open, setOpen] = useState(false);
    useEffect(() => { setOpen(true) }, [])
    return (
        <>
            <div className="lg:hidden bg-white shadow h-16 fixed top-0 w-full z-30"></div>
            <Transition appear show={open} >
                <main className="lg:pr-4 p-4 lg:pt-24 pt-20 transition duration-1000 data-[closed]:opacity-0">
                    <TransitionChild>
                        <div className="min-h-full transition duration-500 data-[closed]:translate-y-24">
                            <CatchBoundary
                                getResetKey={() => 'reset'}
                                onCatch={(error) => console.error(error)}
                            >
                                <Dashboard />
                            </CatchBoundary>
                        </div>
                    </TransitionChild>
                </main>
            </Transition>
        </>
    )
}

function Dashboard() {
    const { data } = useQuery(dashboardDataQueryoptions())
    const { data: users } = useQuery(paginatedUsersQueryOptions())
    const { user } = useLoaderData({ from: "/_protected" })
    const isAdmin = checkIsAdmin(user?.role?.value || '');

    if (!data || !users) {
        return <ContentLoading />
    }

    return (
        <>
            <section className='mx-auto max-w-body w-full mb-5'>
                <div className='xl:grid grid-cols-12 max-xl:flex gap-5 max-xl:flex-wrap'>
                    <div className='col-span-3 flex flex-col flex-none gap-5 md:basis-72 max-md:flex-1'>
                        <OpeningHoursCard {...data.data.infoSnippets.openingHours} />
                        <ListCard topVehicles={data.data.infoSnippets.topVehicles} />
                    </div>
                    <div className='col-span-5 flex-1 max-xl:order-3 max-xl:basis-full max-xl:w-full'>
                        <LatestCasesListCard latestCases={data.data.latestCases} />
                    </div>
                    <div className='col-span-4 flex flex-col gap-5 xl:basis-[338px] flex-none max-xl:flex-1 max-xl:order-2 max-md:basis-full'>
                        <SubscriptionCard />
                        <UsersCard isAdmin={isAdmin} users={users} />
                    </div>
                </div>
            </section>
            <section className='mx-auto max-w-body w-full'>
                <CardSlider newsFeed={data.data.newsFeed} />
            </section>
        </>
    )
}

function CardSlider({ newsFeed }: { newsFeed: DashboardData['data']['newsFeed'] }) {
    if (!newsFeed.length) {
        return null;
    }
    return (
        <div className={clsx(
            "xl:grid xl:grid-cols-4 flex flex-nowrap sm:[&>*]:basis-1/4 [&>*]:flex-none gap-5",
            "max-sm:[&>*]:basis-5/6 max-md:no-scrollbar overflow-x-auto snap-x snap-mandatory [&>*]:snap-always [&>*]:snap-start"
        )}>
            {newsFeed.map((item, i) => (
                <DashboardCard key={i}  {...item} />
            ))}
        </div>
    )
}

function OpeningHoursCard({ from, to }: DashboardData['data']['infoSnippets']['openingHours']) {
    function formatTime(time: string) {
        const date = new Date(time);
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");

        return `${hours}:${minutes}`
    }
    return (
        <>
            <div className="bg-white rounded-sm border flex flex-col">
                <div className="flex gap-3 p-5 border-b">
                    <span className="bg-gray-100 size-10 rounded-lg flex-none flex items-center justify-center">
                        <ClockIcon className="text-text-primary/90 fill-text-primary/15 size-6" />
                    </span>
                    <div>
                        <p className="text-xs font-medium">{t("openingHoursWorkday")}</p>
                        <p className="text-dark-blue font-medium leading-5">{formatTime(from)}-{formatTime(to)}</p>
                    </div>
                </div>
                <div className='p-5 pt-4'>
                    <p className="mt-auto text-sm">{t("content:generic.Contact us all weekdays during this period")}</p>
                    <a className="block text-sm font-normal link mt-4" href={`mailto:${companyConfig.emails.info}`}>
                        <AtSymbolIcon className='size-4 inline mr-1' aria-hidden="true" />
                        {companyConfig.emails.info}
                    </a>
                    <a className="block text-sm font-normal link mt-2" href={`tel:${companyConfig.phone.company}`}>
                        <PhoneIcon className='size-4 inline mr-1' aria-hidden="true" />
                        {formatPhone(companyConfig.phone.company)}
                    </a>

                </div>
            </div>
        </>
    )
}


function ListCard({ topVehicles }: { topVehicles: DashboardData['data']['infoSnippets']['topVehicles'] }) {
    const [isOpen, setIsOpen] = useState(false)
    function openArticle() {
        setIsOpen(true)
    }
    function closeArticle() {
        setIsOpen(false)
    }
    return (
        <>
            <div className="bg-white rounded-sm border h-full flex flex-col">
                <div className='p-5 border-b'>
                    <div className="flex gap-3">
                        <span className="bg-gray-100 flex-none size-10 rounded-lg flex items-center justify-center">
                            <ChartBarIcon className="text-text-primary/90 fill-text-primary/15 size-6" />
                        </span>
                        <div>
                            <p className="text-xs font-medium">{t("analysis")}</p>
                            <p className="text-dark-blue font-medium leading-5">{t("content:generic.mostCalculatedCars")}</p>
                        </div>
                    </div>
                </div>
                <ol className="list-decimal list-inside text-sm font-medium bg-gray-50 text-dark-blue">
                    {topVehicles.slice(0, 7).map((item, i) => (
                        <li key={i} className="px-4 py-2.5 h-[42px] border-b whitespace-nowrap text-ellipsis overflow-hidden" title={item}>{item}</li>
                    ))}
                </ol>
                <div className='p-5 mt-auto'>
                    <Button className="btn btn-gray w-full"
                        onClick={openArticle}
                    >
                        {t("viewEntireList")}
                    </Button>
                </div>
            </div>


            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={closeArticle}>
                <DialogBackdrop transition className="fixed inset-0 bg-dark-blue/30 backdrop-[1px]" />
                <div className="fixed inset-0 z-10 sm:pt-24 pt-16 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-2xl bg-white rounded-lg p-4 relative duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <Button onClick={closeArticle} className="btn btn-gray absolute right-2 top-2 size-10 p-0"><XMarkIcon className="size-5" aria-hidden="true" /></Button>
                            <h3 className="text-lg text-dark-blue font-medium">
                                Mest beregnede biler
                            </h3>

                            <ol className="list-decimal list-inside text-sm font-medium text-dark-blue">
                                {topVehicles.map((item, i) => (
                                    <li key={i} className="px-4 py-2 border-b whitespace-nowrap text-ellipsis overflow-hidden" title={item}>{item}</li>
                                ))}
                            </ol>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

function getNextRenewalDate(period: [string, string], endsAt?: string | null) {
    if (endsAt) {
        return `Udløber: ${formatDate(period[1])} ${formatTime(period[1])}`
    }
    return `Fornyes: ${formatDate(period[1])} ${formatTime(period[1])}`
}
function SubscriptionCard() {
    const { user } = useLoaderData({ from: "/_protected" })

    const hasSubscription = !!user.subscription.details
    return (
        <>
            <div className="flex flex-col relative z-0 bg-white rounded-sm border">
                <div className='p-5 border-b'>
                    <div className="flex gap-3">
                        <span className="bg-gray-100 flex-none size-10 rounded-lg flex items-center justify-center">
                            <CreditCardIcon className="text-text-primary/90 fill-text-primary/15 size-6" />
                        </span>
                        <div>
                            <p className="text-xs font-medium">{t("subscription")}</p>
                            <p className="text-dark-blue font-medium leading-5">{hasSubscription ? user.subscription.details?.package?.title : subscriptionConfig.starterSubscription.title}</p>
                        </div>
                    </div>
                </div>

                <div className='p-5 flex flex-col h-full'>
                    <div className="flex justify-between items-center mb-4">
                        <span className="rounded-[3px] inline-block px-2.5 py-0.5 whitespace-nowrap font-medium text-[12px] leading-5 bg-cyan-100 text-cyan-600">{t("clip_other")}: {user?.credits?.used}/{user?.credits?.total}</span>
                        <span className="text-[10px] font-medium">{getNextRenewalDate(user.subscription.period, user.subscription.details?.endsAt)}</span>
                    </div>
                    <Link className={"mt-auto btn btn-gray"} to="/dealership/subscription">{t("administerSubscription")}</Link>
                </div>
            </div>
        </>
    )
}


type UsersCardProps = {
    users: PaginatedData<UserResource['data']>;
    isAdmin: boolean
}
function UsersCard({ users, isAdmin }: UsersCardProps) {
    const [selectedUser, setSelectedUser] = useState<UserResource['data'] | null>(null)
    function onCloseEditUser() {
        setSelectedUser(null)
    }

    function onInviteNewUser() {
        setInviteUserOpen(true);
    }

    const [inviteUserOpen, setInviteUserOpen] = useState(false)

    return (
        <div className="flex flex-col flex-1 relative z-0 bg-white rounded-sm border">
            <div className='p-5 border-b'>
                <div className="flex gap-3">
                    <span className="bg-gray-100 flex-none size-10 rounded-lg flex items-center justify-center">
                        <UsersIcon className="text-text-primary/90 fill-text-primary/15 size-6" />
                    </span>
                    <div>
                        <p className="text-xs font-medium">{t("overview")}</p>
                        <p className="text-dark-blue font-medium leading-5">{t("users")} ({users.meta.total})</p>
                    </div>
                </div>
            </div>
            <ul className='bg-gray-50'>
                {users.data.map((user) => (
                    <li key={user.id} className='px-5 h-14 flex items-center gap-4 border-b'>
                        <Avatar
                            imgSrc={user.avatar?.uri}
                            initials={user.initials}
                            size='md'
                        />
                        <div>
                            <p className='text-sm font-medium text-dark-blue'>{user.name}</p>
                            <p className='text-sm'>{user.role.name}</p>
                        </div>
                        <Button disabled={!isAdmin}
                            className="btn btn-text ml-auto -mr-4"
                            onClick={() => setSelectedUser(user)}
                        >
                            <PencilIcon className='w-5 h-5' aria-label={t("edit.fix")} />
                        </Button>
                    </li>
                ))}
            </ul>
            <div className='p-5 mt-auto flex gap-2 flex-wrap'>
                <Link
                    to='/dealership/users'
                    search={{ page: 1, per_page: 10 }}
                    className='btn btn-gray flex-1 whitespace-nowrap'
                >
                    {t("viewAll")}
                </Link>
                <Button onClick={onInviteNewUser}
                    className="btn btn-primary flex-1 whitespace-nowrap">
                    {t("inviteNewUser")}
                </Button>
            </div>
            <EditDealerUser user={selectedUser} onClose={onCloseEditUser} />
            <InviteNewUser open={inviteUserOpen} setOpen={setInviteUserOpen} />
        </div>
    )
}



type LatestCasesListCardProps = {
    latestCases: DashboardData['data']['latestCases']
}
// tax == 42.66px
// leasing == 101px
// import == 79px
function LatestCasesListCard({ latestCases }: LatestCasesListCardProps) {
    latestCases = latestCases.slice(0, 7);
    return (
        <div className="bg-white rounded-sm border h-full flex flex-col w-full">
            <div className='p-5'>
                <div className="flex gap-3">
                    <span className="bg-gray-100 flex-none size-10 rounded-lg flex items-center justify-center">
                        <DocumentMagnifyingGlassIcon className="text-text-primary/90 fill-text-primary/15 size-6" />
                    </span>
                    <div>
                        <p className="text-xs font-medium">{t("overview")}</p>
                        <p className="text-dark-blue font-medium leading-5">{t("latestTaxCases")}</p>
                    </div>
                </div>
            </div>
            <div className='w-full bg-gray-50 overflow-clip border-t'>
                <div className='border-b flex text-xs font-medium gap-2.5 py-1.5 px-4 max-sm:hidden'>
                    <span className='flex-1'>Køretøj</span>
                    <span className='sm w-12'>#</span>
                    <span className='w-32'>Status</span>
                </div>
                {latestCases?.map((item, i) => (
                    <div key={item.id + "_" + i} className="border-b flex items-center sm:h-[74px] sm:gap-2.5 gap-1.5 py-2 px-4 sm:text-sm text-xs">

                        {item.vehicle?.image?.uri ? (
                            <img className="w-auto h-[50px]" src={item.vehicle?.image?.uri} alt={item.vehicle?.make || ""} loading="lazy" />
                        ) : (
                            <CarLogo carBrand={item.vehicle?.make ?? "fallback"} />
                        )}

                        <span title={`${printMakeModelOrLink(item.vehicle?.make, item.vehicle?.model, item.vehicle?.link, 'link-as-text')} ${item.vehicle?.vin ?? ""} | ${formatDate(item.vehicle?.firstRegDate)} | ${formatNumber(item.vehicle?.mileage)} KM.`}
                            className="flex-1 sm:truncate"
                        >
                            <p className="text-dark-blue font-medium max-sm:line-clamp-2 sm:truncate">
                                {printMakeModelOrLink(item.vehicle?.make, item.vehicle?.model, item.vehicle?.link)}
                            </p>
                            <div className='text-xs max-sm:flex flex-col sm:truncate'>
                                {item.vehicle?.vin && (
                                    <span>
                                        {item.vehicle?.vin}
                                        <span className='inline-block mx-2 w-0 h-4 border-r align-bottom max-sm:hidden'></span>
                                    </span>
                                )}
                                {item.vehicle?.firstRegDate && (
                                    <span className='max-sm:hidden'>
                                        {formatDate(item.vehicle?.firstRegDate)}
                                        <span className='inline-block mx-2 w-0 h-4 border-r align-bottom max-sm:hidden'></span>
                                    </span>
                                )}
                                {item.vehicle?.firstRegDate && (
                                    <span className='max-sm:hidden'>
                                        {formatNumber(item.vehicle?.mileage)} KM.
                                    </span>
                                )}


                            </div>
                        </span>
                        <div className='flex max-sm:flex-col max-sm:items-end flex-none sm:gap-2.5 gap-1.5'>
                            <span className='sm:w-12'>
                                {renderLink(item.type, item.id)}
                            </span>
                            <span className="sm:w-32">
                                <StatusTag {...item.status} />
                            </span>
                        </div>
                    </div>
                ))}


            </div>
            <div className='p-5 mt-auto'>
                <Link to="/tax" className="btn btn-gray w-full">
                    {t("viewTaxCases")}
                </Link>
            </div>
        </div>

    )
}

function CarLogo({ carBrand }: { carBrand: string | undefined }) {
    const src = useFallbackCarLogo(carBrand);
    return (
        <img className="w-auto h-10 mix-blend-multiply" src={src} alt={carBrand} loading="lazy" />
    )
}


function renderLink(item: EnumItem, id: string | number) {
    if (item.value === 'leasing') {
        return (
            <Link to={"/leasing/$id"} params={{ id: String(id) }}
                className="link"
            >
                {id}
            </Link>
        )
    }
    if (item.value === 'import') {
        return (
            <Link to={"/import/$id"} params={{ id: String(id) }}
                className="link"
            >
                {id}
            </Link>
        )
    }
    return (
        <Link to={"/tax/$id"} params={{ id: String(id) }}
            className="link"
        >
            {id}
        </Link>
    )
}

import CreateTaxCase from '@/features/tax/CreateTaxCase'
import { CreateTaxCaseByLink, CreateTaxCaseByVin } from '@/types/taxTypes'
import { Transition, TransitionChild } from '@headlessui/react'
import { createFileRoute } from '@tanstack/react-router'
import { useEffect, useState } from 'react'

type RedirectSearch = {
    value?: "success" | "cancel"
    additional?: {
        type: "tax",
    } & ({
        sourceType: 1,
        sourceValue?: CreateTaxCaseByLink
    } | {
        sourceType: 2,
        sourceValue?: CreateTaxCaseByVin
    })
}

export const Route = createFileRoute('/_protected/_tax/tax/create')({
    validateSearch: (s: Record<string, unknown>): RedirectSearch => {
        if (s) {
            return {
                value: (s?.value as RedirectSearch['value']) || undefined,
                additional: (s?.additional as RedirectSearch['additional']) || undefined
            }
        }
        return {}
    },
    meta: () => [{ title: "TAX · NY" }],
    component: Create
})

function Create() {
    // HeadlessUI <Transition appear> does not work on page reload, but only on mount WHEN the app is already running.
    const [open, setOpen] = useState(false)
    useEffect(() => {
        setOpen(true)
    }, [])
    return (
        <div className="max-w-4xl w-full mx-auto sm:p-8">
            <Transition appear show={open} as="div" className="transition duration-1000 data-[closed]:opacity-0">
                <TransitionChild>
                    <div className="bg-white sm:rounded-lg sm:shadow transition duration-500 data-[closed]:translate-y-24">
                        <CreateTaxCase />
                    </div>
                </TransitionChild>
            </Transition>
        </div>
    )
}
